import { TextField, useMediaQuery } from "@mui/material";


const QuestionTextField = ({value, onChange, name, errorState, onKeyDown, onKeyUp }) => {
    const isMobile = useMediaQuery("(max-width: 768px)");

    const textFieldStyles = {
        '.MuiOutlinedInput-root': {
            borderRadius: '12px',
            border: '1px solid #35394A',
            height: "55px",
            '&:hover': {
                borderColor: '#AFAFBB',  // Hover color
                '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                    '&:hover': {
                        borderColor: 'transparent',
                    },
                },
            },
            '&.Mui-error':{
                '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                    '&:hover': {
                        borderColor: 'transparent',
                    },
                },
                borderColor: '#FF6E66' // Error color
            },
            '&.Mui-focused': {
                borderColor: '#AFAFBB',  // Focus color
                '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                    '&:hover': {
                        borderColor: 'transparent',
                    },
                },
            },
            '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
                '&:hover': {
                    borderColor: 'transparent',
                },
            },
            input: {
                padding: '18px 20px',
                borderRadius: '12px',
                fontSize: '16px',
                fontWeight: 400,
                color: '#D3D3DA',
                lineHeight: '19.2px',
                '&:-webkit-autofill': {
                    appearence: "none",
                    backgroundColor: '#121317 !important',
                    WebkitBoxShadow: '0 0 0px 60px #121317 inset !important',
                    WebkitTextFillColor: '#D3D3DA !important',
                    padding: isMobile ? "14px" : "15px",
                    border: "none"
                },
                '&:-internal-autofill-selected': {
                    appearence: "none",
                    backgroundColor: '#121317 !important', 
                    WebkitBoxShadow: '0 0 0px 60px #121317 inset !important',
                    WebkitTextFillColor: '#D3D3DA !important',
                    padding: isMobile ? "14px" : "15px",
                    border: "none"
                },
            },
        },
        '@media (max-width: 768px)': {
            '.MuiOutlinedInput-root': {
                height: '55px',
                input: {
                    fontSize: '16px',
                },
            },
        },
    };

    return (
        <TextField
            label=""
            type="text"
            size="small"
            placeholder=""
            fullWidth
            name={name}
            variant="outlined"
            value={value}
            onChange={(e) => onChange(e)}
            onKeyDown={(e) => onKeyDown(e)}
            onKeyUp={(e) => onKeyUp(e)}
            sx={textFieldStyles}
            error={errorState}
        />
    );
};

export default QuestionTextField;
