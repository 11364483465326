import { Box, Button, LinearProgress, styled, TextField, Typography, useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react'
import MainContext from '../../context/MainContext';
// import 'react-phone-input-2/lib/style.css'
// import screeningQuestionsJson from '../json/screening_questions.json';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
// import Swal from 'sweetalert2';
import "./Eligibility.scss";
import { handlePatchApiCall } from '../../api-functions/CommonApiCall';
import { checkEligibilityURL } from '../../api-functions/ApiURLs';
import { useNavigate } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import { trackCompleteCheckEligibilityEvent } from '../common/Amplitute';
import QuestionTextField from '../common/elements/QuestionTextField';
import { commonStyles } from '../common/elements/commonStyle';
import { Squircle } from 'corner-smoothing'

const ScreeningQuestionnaire = () => {

  const isMobile = useMediaQuery("(max-width: 768px)");

  const { 
      setIsPreLoading, 
      ShowNotification, 
      leadId, 
      setLeadEligibility, 
      setScreeningQuestionnaireAnswers,
      questionnaireObject, setQuestionnaireObject,
      currentQuestionId, setCurrentQuestionId,
      answeredQuestionsStack, setAnsweredQuestionsStack 
    } = useContext(MainContext);
    

  // const [questionnaireObject, setQuestionnaireObject] = useState(screeningQuestionsJson);

  const navigate = useNavigate();

  // const [answeredQuestionsStack, setAnsweredQuestionsStack] = useState([]);
  // const [currentQuestionId, setCurrentQuestionId] = useState(1);
  const [currentQuestion, setCurrentQuestion] = useState([]);
  const [isVisible, setIsVisible] = useState(true); //Question - Animation
  const nextQuestionButtonRef = useRef(null)

  const [progressLevel, setProgressLevel] = useState(0)

  const handleClose = () => {
    // setQuestionnaireObject(screeningQuestionsJson);
    // setAnsweredQuestionsStack([])
    // setCurrentQuestionId(1)
    navigate("/screening-result")
  };


  useEffect(() => {
    if (currentQuestionId === 15 || currentQuestionId === 16) {

      handleSubmitResponse(currentQuestionId === 16);

      // Swal.fire({
      //   title: "Are you sure to complete the questionnaire?",
      //   showCancelButton: true,
      //   confirmButtonText: "Yes",
      //   denyButtonText: `No`,
      //   icon: "warning"
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     handleSubmitResponse(currentQuestionId === 16);
      //   } else if (result.isDenied) {
      //     ShowNotification("info", "Sending cancelled!");
      //   }
      // });

      const lastAnsweredQuestionID = answeredQuestionsStack.pop();
      setAnsweredQuestionsStack([...answeredQuestionsStack]);
      setCurrentQuestionId(lastAnsweredQuestionID);
    } else {
      const question = questionnaireObject?.find((item) => item.id === currentQuestionId);
      setCurrentQuestion(question);
    }
  }, [currentQuestionId])

  useEffect(() => {
    if (currentQuestionId) {
      let totalQuestion = 14;
      setProgressLevel((100 / totalQuestion) * currentQuestionId)
    }

  }, [currentQuestionId])

  const handleSubmitResponse = async (eligibleState) => {

    let updatedData = updateDependencyQuestionnaire();
    setQuestionnaireObject(updatedData);

    const convertedResponseData = updatedData?.map(item => ({
      id: item.id,
      ref: item?.ref || "",
      heading: item.heading,
      input_type: item.input_type,
      question: item.question,
      data: {
        value: item?.data?.value || ""
      }
      // value: item?.data?.value || ""
    }));

    try {
      setIsPreLoading(true);

      const payload = {
        lead_id: leadId,
        questionnaire_response: convertedResponseData,
        is_eligible: eligibleState,
      };

      const response = await handlePatchApiCall(checkEligibilityURL, payload);

      setLeadEligibility(eligibleState)

      setScreeningQuestionnaireAnswers()
      handleClose()
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error.response.data?.message);
    } finally {
      setIsPreLoading(false);
      trackCompleteCheckEligibilityEvent()
    }
  }

  function updateDependencyQuestionnaire() {

    const updatedQuestions = questionnaireObject?.map((question) => {

      if (question.is_sub_question) {
        const parentQuestion = questionnaireObject.find(
          (q) => q.id === question.parent_question_id
        );

        if (parentQuestion) {
          if (!question.allowedValues.includes(parentQuestion.data.value)) {
            return { ...question, data: { ...question.data, value: "" } };
          }
        }
      }

      return question;
    });

    return updatedQuestions;
  }

  const updateDataValueById = (id, newValue, inputType) => {
    const updatedQuestionnaire = questionnaireObject.map((item) =>
      item.id === id
        ? {
          ...item,
          data: {
            ...item.data,
            value: newValue,
          },
        }
        : item
    );

    const updatedCurrentQuestion = {
      ...currentQuestion,
      data: {
        ...currentQuestion.data,
        value: newValue,
      },
    };

    setQuestionnaireObject(updatedQuestionnaire);
    setCurrentQuestion(updatedCurrentQuestion);

    if (inputType === "radio" && currentQuestionId !== 14) {
      setIsVisible(false)
      setTimeout(() => {
        handleThroughClick(updatedQuestionnaire, updatedCurrentQuestion)
        setIsVisible(true)
      }, 400);
    }
  }

  const handleNextButtonClick = () => {
    let currentQnData = questionnaireObject?.find((item) => item?.id === currentQuestionId);

    if (currentQnData?.sub_type === "medication") {

      if (!currentQuestion?.data?.value?.includes("None")) {
        setCurrentQuestionId(15)
      } else {
        setCurrentQuestionId(currentQnData?.next_question)
      }

    } else if (currentQnData?.sub_type === "medication_allergy") {

      if (currentQuestion?.data?.value?.includes("Warfarin")) {
        setCurrentQuestionId(15)
      } else {
        setCurrentQuestionId(currentQnData?.next_question)
      }

    } else {

      if (currentQnData?.next_question) {
        setCurrentQuestionId(currentQnData?.next_question)
      } else {
        let optionNextQuestion = currentQnData?.options?.find(option => option?.value === currentQnData?.data?.value)?.next_question;
        setCurrentQuestionId(optionNextQuestion)
      }

    }

    setAnsweredQuestionsStack((prevStack) => [
      ...prevStack,
      currentQuestion?.id,
    ]);
  }

  const handleThroughClick = (questionnaireObject, currentQuestion) => {
    let currentQnData = questionnaireObject?.find((item) => item?.id === currentQuestionId);

    if (currentQnData?.sub_type === "medication") {

      if (!currentQuestion?.data?.value?.includes("None")) {
        setCurrentQuestionId(15)
      } else {
        setCurrentQuestionId(currentQnData?.next_question)
      }

    } else if (currentQnData?.sub_type === "medication_allergy") {

      if (currentQuestion?.data?.value?.includes("Warfarin")) {
        setCurrentQuestionId(15)
      } else {
        setCurrentQuestionId(currentQnData?.next_question)
      }

    } else {

      if (currentQnData?.next_question) {
        setCurrentQuestionId(currentQnData?.next_question)
      } else {
        let optionNextQuestion = currentQnData?.options?.find(option => option?.value === currentQnData?.data?.value)?.next_question;
        setCurrentQuestionId(optionNextQuestion)
      }

    }

    setAnsweredQuestionsStack((prevStack) => [
      ...prevStack,
      currentQuestion?.id,
    ]);
  }

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: 3,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#1D1F26'
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 3,
      backgroundColor: '#71F0FF'
    },
  }));


  const questionnaireStyle = {
    block: {
      background: "#121317",
      padding: isMobile ? "20px" : "60px",
      borderRadius: "20px",
      display: "flex",
      flexDirection: "column",
      gap: "40px",

      '@media (max-width: 768px)': {
        padding: "20px"
      },
    },
    questionnaireBlock:{
      display: "flex",
      flexDirection: "column",
      gap: isMobile ? "22px" : "32px",
    },
    questionsTop:{
      display: "flex",
      flexDirection: "column",
      gap: isMobile ? "28px" : "40px",
    },
    progressBar: 
    { 
      width: '100%', 
      mr: 1,
    },
    questionText: {
      fontSize: "20px",
      fontWeight: 400,
      lineHeight: "26.5px",
      textAlign: "left",
      textUnderlinePosition: "from-font",
      textDecorationSkipInk: "none",
      color: "#D3D3DA",
      marginBottom: "0.75rem",
      '@media (max-width: 768px)': {
        fontSize: '18px',
        lineHeight: '22px',
      },
    },
    description: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "21.22px",
      textAlign: "left",
      textUnderlinePosition: "from-font",
      textDecorationSkipInk: "none",
      // color: "#D3D3DA",
      marginBottom: "0.75rem",
      color: "#FF8680",
      '@media (max-width: 768px)': {
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
    radioOption: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",

      label: {
        width: "100%",
        height: isMobile ? "47px" : "55px",
        padding: isMobile ? "14px 16px" : "14px 16px",
        gap: "24px",
        borderRadius: isMobile ? "10px" : "12px",
        border: "1px solid #35394A",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20.2px",
        textAlign: "left",
        textUnderlinePosition: "from-font",
        textDecorationSkipInk: "none",
        color: "#D3D3DA",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        svg: {
          color: "#71F0FF",
          fontSize: "19px"
        }
      }
    },
    optionsBlock:{
      display: "flex",
      flexDirection: "column",
      gap: isMobile ? "12px" : "20px",
    },
    checkBoxOption: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",

      label: {
        width: "100%",
        height: isMobile ? "47px" : "55px",
        padding: isMobile ? "14px 16px" : "14px 16px",
        gap: "24px",
        borderRadius: "12px",
        border: "1px solid #35394A",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "19.2px",
        textAlign: "left",
        textUnderlinePosition: "from-font",
        textDecorationSkipInk: "none",
        color: "#D3D3DA",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        svg: {
          color: "#71F0FF",
          fontSize: "19px"
        }
      }
    },
    bottomNavigation: {
      display: "flex",
      justifyContent: "start",
      gap: isMobile ? "20px" : "40px",
      marginTop: "-1rem"
    }
  }

  const handleTextFieldChange = (event) => {
    const value = event.target.value;
    // if (value === "" || /^[1-9]\d*$/.test(value)) {
    if (value === "" || (/^[1-9]\d{0,1}$/.test(value))) {
      updateDataValueById(currentQuestion?.id, value, currentQuestion?.input_type)
    }
  }
  const handleTextFieldOnClick = (event) => {
    const invalidKeys = ["e", ".", "-"];
    if (invalidKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  const handleTextFieldKeyUp = (event) => {
    if (event.key === "Enter" && nextQuestionButtonRef.current) {
      nextQuestionButtonRef.current.click();
    }
  }

  return (
    <div className='screening-questionnaire-section'>
      <Squircle cornerRadius={30} style={questionnaireStyle?.block} >
      {/* <Box sx={questionnaireStyle?.block}> */}

        <Box sx={questionnaireStyle?.questionsTop}>
          <Box sx={questionnaireStyle?.progressBar}>
            <BorderLinearProgress variant="determinate" value={progressLevel} />
          </Box>

          {/* {questionnaireObject?.map((question, index) => ( */}
          <section key={1} className={`questionnaire-block ${isVisible ? "fade-in" : "fade-out"}`} style={questionnaireStyle?.questionnaireBlock}>
            <div>
              <Typography variant="p" component="p" sx={questionnaireStyle?.questionText}>
                {currentQuestion?.question}
              </Typography>

              {(currentQuestion?.data?.value && (currentQuestion?.data?.value < 18 || currentQuestion?.data?.value > 80)) &&
                <Typography variant="p" component="p" sx={questionnaireStyle?.description}>
                  {currentQuestion?.description}
                </Typography>
              }
            </div>
            {/* <Box sx={{ mt: "32px" }} > </Box> */}
            <div>
              {currentQuestion?.input_type === "text" && (
                <QuestionTextField
                  value={currentQuestion?.data?.value}
                  onChange={handleTextFieldChange}
                  onKeyDown={handleTextFieldOnClick}
                  onKeyUp={handleTextFieldKeyUp}
                  name="age"
                />
              )}

              {currentQuestion?.input_type === "radio" && (
                <div className="d-flex flex-column" style={questionnaireStyle?.optionsBlock}>
                  {currentQuestion?.options.map((option, optionIndex) => {
                    return (
                      <div key={optionIndex} className="radio-option" style={questionnaireStyle?.radioOption}>
                        <RadioButton
                          inputId={`${currentQuestion?.id}-${optionIndex}`}  // Unique ID per option
                          value={option.value}
                          style={{ display: "none" }}
                          onChange={(e) => {
                            updateDataValueById(currentQuestion?.id, option.value, currentQuestion?.input_type)
                          }}
                          checked={currentQuestion?.data?.value === option.value}
                        />
                        <label
                          htmlFor={`${currentQuestion?.id}-${optionIndex}`}
                          style={questionnaireStyle?.radioOption?.label}
                        >
                          <span style={{ marginTop: "2px" }}>{option?.label}</span>
                          {currentQuestion?.data?.value === option.value &&
                            <FontAwesomeIcon icon={faCircleCheck} style={questionnaireStyle?.radioOption?.label?.svg} />
                          }
                        </label>
                      </div>
                    );
                  })}
                </div>
              )}

              {currentQuestion?.input_type === "checkbox" && (
                <div className="d-flex flex-column" style={questionnaireStyle?.optionsBlock}>
                  {currentQuestion?.options.map((option, optionIndex) => {
                    return (
                      <div key={optionIndex} className="checkbox-option" style={questionnaireStyle?.checkBoxOption}>
                        <label
                          htmlFor={`${currentQuestion?.id}-${optionIndex}`}
                          style={questionnaireStyle?.checkBoxOption?.label}
                        >
                          <span style={{ marginTop: "2px" }}>{option?.label}</span>

                          {currentQuestion?.data?.value?.includes(option.value) ?
                            (<FontAwesomeIcon icon={faCircleCheck} style={questionnaireStyle?.checkBoxOption?.label?.svg} />) :
                            (<FontAwesomeIcon icon={faCircle} style={{ ...questionnaireStyle?.checkBoxOption?.label?.svg, color: "#A1A1AF" }} />)
                          }
                        </label>
                        <Checkbox
                          inputId={`${currentQuestion?.id}-${optionIndex}`}
                          value={option.value}
                          onChange={(e) => {
                            let values = [...(currentQuestion?.data?.value || [])];
                            let selectedValue = e.value;

                            if (e.checked) {
                              if (currentQuestion?.sub_type === "medication" && e.value !== "None") {
                                values = values.filter(value => value !== "None");
                                values.push(selectedValue);
                              } else {
                                values.push(selectedValue);
                              }
                            } else {
                              const index = values.indexOf(selectedValue);
                              if (index !== -1) values.splice(index, 1);
                            }
                            if (currentQuestion?.sub_type === "medication_allergy") {
                              updateDataValueById(
                                currentQuestion?.id,
                                values,
                                currentQuestion?.input_type
                              );
                            } else {
                              updateDataValueById(
                                currentQuestion?.id,
                                selectedValue === "None" && e.checked ? ["None"] : values?.length === 0 ? ["None"] : values,
                                currentQuestion?.input_type
                              );
                            }

                          }}
                          checked={currentQuestion?.data?.value?.includes(option.value)}
                        />

                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </section>
          {/* ))}           */}
        </Box>

        <Box sx={questionnaireStyle.bottomNavigation}>
          {currentQuestionId !== 1 && (
            <Button
              variant="contained"
              size='small'
              color='success'
              sx={commonStyles?.button.outlined}
              disabled={currentQuestionId <= 1}
              onClick={() => {
                setIsVisible(false)
                setTimeout(() => {
                  const lastAnsweredQuestionID = answeredQuestionsStack.pop();
                  setAnsweredQuestionsStack(answeredQuestionsStack);
                  setCurrentQuestionId(lastAnsweredQuestionID)
                  setIsVisible(true)
                }, 400);
              }}
            >
              <span>{"Back"}</span>
            </Button>
          )}

          <Button
            variant="contained"
            size='small'
            color='success'
            sx={commonStyles?.button.contained}
            ref={nextQuestionButtonRef}
            disabled={
              !currentQuestion?.data?.value || !currentQuestion?.data?.value?.length ||
              (currentQuestion?.sub_type === "age" &&
                (currentQuestion.data.value < 18 || currentQuestion.data.value > 80))
            }
            onClick={() => {
              setIsVisible(false)
              setTimeout(() => {
                handleNextButtonClick()
                setIsVisible(true)
              }, 400);
            }}
          >
            <span>
              {currentQuestionId === 14 ? "Submit" : "Next"}</span>
          </Button>
        </Box>
      {/* </Box> */}
      </Squircle>

    </div>
  )
}

export default ScreeningQuestionnaire