import React from "react";
import Select, {
    components
} from "react-select";
import RadioButton from '../../../assets/svg/RadioButton.svg';
import RadioButtonEnabled from '../../../assets/svg/RadioButtonEnabled.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "@mui/material";

const CountrySelect = ({ optionsData, selectedOption, onChange, selectRef, openDropdown }) => {

    const isMobile = useMediaQuery("(max-width: 768px)");

    const autoCompleteCustomStyles = {
        control: (provided, state) => ({
            ...provided,
            height: "55px",
            // backgroundColor: "#121317",
            borderColor: "#AFAFBB",
            borderRadius: "12px",
            color: "white",
            padding: "6px",
            boxShadow: state.isFocused ? "none" : "none",
            position: "absolute",
            width: "100%",
            // transform: "translate(0px, 54px)",
            backgroundColor: "#0D0E11",
            zIndex: "9999",
            "&:hover": {
                borderColor: "#AFAFBB",
            },
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: "#121317",
        }),
        option: (provided, { isSelected, isFocused }) => ({
            ...provided,
            display: "flex",
            alignItems: "center",
            backgroundColor: isSelected ? "#313131" : isFocused ? "#232323" : "#121317",
            cursor: "pointer",
            color: "white",
            padding: "10px 4px",
            fontSize: "16px",
            // borderBottom: "1px solid #3D3D48"
        }),
        input: (provided) => ({
            ...provided,
            color: "white",
            fontSize: "16px"
        }),
        singleValue: (provided) => ({
            ...provided,
            color: "white",
            display: "flex",
            alignItems: "center",
        }),
    };

    const options = optionsData?.map((data) => ({
        value: data.name,
        label: (
            <section style={{ width: "100%" }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                    }}>
                    <div style={{ textAlign: "left" }}>
                        <img
                            src={`https://flagcdn.com/w40/${data?.iso2?.toLowerCase()}.png`}
                            alt={data.name}
                            style={{ width: "19px", height: "auto", borderRadius: "2px", marginRight: "12px" }}
                        />
                        <span style={{ fontSize: isMobile ? "14px" : "16px" }}>{data?.name} {`(+${data?.phone_code})`}</span>
                    </div>
                    <img
                        src={selectedOption?.name === data.name ? RadioButtonEnabled : RadioButton}
                        style={{ width: "20px" }}
                        alt="checkboxButton"
                    />
                </div>
            </section>
        ),
        displayLabel: data.name,
    }));

    const Control = ({ children, ...props }) => {
        const style = { cursor: 'pointer' };

        return (
            <components.Control {...props}>
                <span style={style}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} style={{color: "#9393A4", fontSize: "19px"}}/>
                </span>
                {children}
            </components.Control>
        );
    };

    const handleMenuOpen = () => {
        setTimeout(() => {
            const menuList = document.querySelector(".auto-complete-select__menu-list");
            const selectedOptionEl = document.querySelector(".auto-complete-select__option--is-selected");

            if (menuList && selectedOptionEl) {
                menuList.scrollTop = selectedOptionEl.offsetTop - menuList.offsetTop + 54;
            }
        }, 10)
    };

    return (
        <Select
            className={`auto-complete-select`}
            classNamePrefix="auto-complete-select"
            styles={autoCompleteCustomStyles}
            options={options}
            value={options.find((opt) => opt.value === selectedOption?.name) || null}
            // getOptionLabel={(e) => e.displayLabel}
            components={{ Control }}
            onChange={(selected) => {
                let filterSelectedOption = optionsData.find((c) => c.name === selected.value);
                onChange(filterSelectedOption)
            }}
            isSearchable
            onMenuOpen={handleMenuOpen}
            placeholder="Search country/region"
            ref={selectRef}
            menuIsOpen={openDropdown}            
            openMenuOnFocus={true}
            // closeMenuOnSelect={true}
            // onMenuScrollToTop={true}
        />
    );
};

export default CountrySelect;
