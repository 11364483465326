import React, { useContext, useEffect, useState } from 'react'
import './PaymentFlow.scss';
import { Button, FormControlLabel, Radio, useMediaQuery } from '@mui/material';
import MainContext from '../../context/MainContext';
import AddressForm from './AddressForm';
import { handlePostApiCall } from '../../api-functions/CommonApiCall';
import { getLeadUserCheckURL, leadAddressUpdateURL } from '../../api-functions/ApiURLs';
import { useNavigate } from 'react-router';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

import countryJSON from '../json/countries_list.json';
import stateJSON from '../json/states_list.json';
import cityJSON from '../json/cities_list.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trackAddressQuestionnaireCompletedEvent, trackWhatsappConsentDisableEvent, trackWhatsappConsentEnableEvent } from '../common/Amplitute';
import { commonStyles } from '../common/elements/commonStyle';
import { Squircle } from 'corner-smoothing';

const getFilteredStates = (selectedCountryId) => {
    const countryStates = stateJSON?.find((state) => state?.id === selectedCountryId);
    return countryStates?.states || [];
};

const getFilteredCities = (selectedCountryId, selectedStateId,) => {
    return cityJSON
        ?.find(({ id }) => id === selectedCountryId)
        ?.states
        ?.find(({ id }) => id === selectedStateId)
        ?.cities || [];
};


const AddressQuestions = () => {

    const navigate = useNavigate();

    const { ShowNotification, setIsPreLoading, leadInfo, setLeadInfo, leadId, paymentQuestionnaireObject, setPaymentQuestionnaireObject, isWhatsappConsent, setIsWhatsappConsent } = useContext(MainContext);

    const isMobile = useMediaQuery("(max-width: 768px)");
    
    const [isSameAddress, setIsSameAddress] = useState(false);
    const [shippingAddress, setShippingAddress] = useState([]);
    const [billingAddress, setBillingAddress] = useState([]);

    useEffect(() => {
        //Initial Apply if PII User
        const shippingAddress = paymentQuestionnaireObject.find((data) => data.id === 22);
        const billingAddress = paymentQuestionnaireObject.find((data) => data.id === 24);
        const isSameAddressQn = paymentQuestionnaireObject.find((data) => data.id === 23)?.data?.value;

        setShippingAddress(shippingAddress);
        setBillingAddress(billingAddress);
        setIsSameAddress(isSameAddressQn === "yes" ? true : false);

        const setAddressDetails = (address, setCountry, setState, setCity) => {
            const { country, state, city } = address?.data?.value || {};
            if (country && state && city) {
                const countryFilter = countryJSON?.find((c) => c?.name === country);
                const stateFilter = getFilteredStates(countryFilter?.id)?.find((s) => s?.name === state);
                const cityFilter = getFilteredCities(countryFilter?.id, stateFilter?.id)?.find((ci) => ci?.name === city);

                setCountry(countryFilter);
                setState(stateFilter);
                setCity(cityFilter);
            }
        };

        setAddressDetails(shippingAddress, setSelectedShippingCountry, setSelectedShippingState, setSelectedShippingCity);
        setAddressDetails(billingAddress, setSelectedBillingCountry, setSelectedBillingState, setSelectedBillingCity);
    }, []);

    const handleShippingAddressChange = (key, value) => {

        const updatedQuestions = paymentQuestionnaireObject.map((question) => {
            if (question?.id === 22) {
                const updatedQuestion = {
                    ...question,
                    data: {
                        value: {
                            ...(question.data?.value || {}),
                            [key]: value,
                        }
                    }
                };
                setShippingAddress(updatedQuestion);
                return updatedQuestion;
            }
            return question;
        });

        setPaymentQuestionnaireObject(updatedQuestions);
    };

    const handleBillingAddressChange = (key, value) => {

        const updatedQuestions = paymentQuestionnaireObject.map((question) => {
            if (question?.id === 24) {
                const updatedQuestion = {
                    ...question,
                    data: {
                        value: {
                            ...(question.data?.value || {}),
                            [key]: value,
                        }
                    }
                };
                setBillingAddress(updatedQuestion);
                return updatedQuestion;
            }
            return question;
        });

        setPaymentQuestionnaireObject(updatedQuestions);
    };

    const handleAddressRadioButtonChange = () => {
        const updatedValue = !isSameAddress;
        setIsSameAddress(updatedValue);

        setPaymentQuestionnaireObject(
            paymentQuestionnaireObject.map((question, index) => {
                if (question?.id === 23) {
                    return { ...question, data: { value: updatedValue ? "yes" : "no" } };
                }
                return question;
            })
        );
    };

    const handleWhatsappConsentChange = async () => {
        const updatedValue = !isWhatsappConsent;
        setIsWhatsappConsent(updatedValue);
        if(updatedValue){
            trackWhatsappConsentEnableEvent()
        }else{
            trackWhatsappConsentDisableEvent()
        }

        if (leadInfo?.mobile && leadInfo?.name?.trim() && leadInfo?.country_code) {
            let payload = {
                country_code: leadInfo?.country_code,
                mobile: leadInfo?.mobile,
                lead_id: leadInfo?.lead_id,
                name: leadInfo?.name,
                lead_source: "website" || leadInfo?.ref_source,
                is_whatsapp_consent: updatedValue,
            };
            // setIsPreLoading(true);
            try {
                await handlePostApiCall(getLeadUserCheckURL, payload)
            } catch (error) {
                console.error("Error fetching data:", error.response ? error.response.data : error.message);
            } finally {
                // setIsPreLoading(false);
            }
        }
    };

    const handleSubmitResponse = async () => {

        const convertedResponseData = paymentQuestionnaireObject?.map(item => ({
            id: item.id,
            ref: item?.ref,
            question: item.question,
            data: {
                value: item?.data?.value
            }
        }));

        const payload = {
            lead_id: leadId,
            questionnaire_response: convertedResponseData,
            is_eligible: true,
        };
        setIsPreLoading(true)
        try {
            const response = await handlePostApiCall(leadAddressUpdateURL, payload);
            setLeadInfo(response?.data?.lead)
            if (response?.status === "success") {
                navigate("/payment");
            }
            trackAddressQuestionnaireCompletedEvent()

        } catch (error) {
            console.error("Error fetching data:", error.response ? error.response.data : error.message);
            ShowNotification("error", "Unable to Submit Address");
        } finally {
            setIsPreLoading(false)
        }
    };

    /*  */

    const countriesList = countryJSON || [];

    const [selectedShippingCountry, setSelectedShippingCountry] = useState(null);
    const [selectedShippingState, setSelectedShippingState] = useState(null);
    const [selectedShippingCity, setSelectedShippingCity] = useState(null);

    const [selectedBillingCountry, setSelectedBillingCountry] = useState(null);
    const [selectedBillingState, setSelectedBillingState] = useState(null);
    const [selectedBillingCity, setSelectedBillingCity] = useState(null);

    /* Button Disable -Condition */
    const isAddressComplete = (address) =>
        address?.data?.value?.address_line1 &&
        address?.data?.value?.zipcode &&
        address?.data?.value?.country &&
        address?.data?.value?.state &&
        address?.data?.value?.city;

    const isSubmitDisabled = isSameAddress
        ? !isAddressComplete(shippingAddress)
        : !isAddressComplete(shippingAddress) || !isAddressComplete(billingAddress);


    const [isCountryChanged, setIsCountryChanged] = useState(false);
    const [isStateChanged, setIsStateChanged] = useState(false);
    const [isCityChanged, setIsCityChanged] = useState(false);

    const formRadioLabelStyle = {
        '& .MuiRadio-root': {
            display: 'none',
        },
        '& .MuiFormControlLabel-label': {
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '1',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            color: '#A1A1AF',
            marginLeft: "1.5rem"
        }
    }
    const formRadioLabelStyle1 = {
        '& .MuiRadio-root': {
            display: 'none',
        },
        '& .MuiFormControlLabel-label': {
            fontSize: '16px',
            fontWeight: 400,
            // lineHeight: '1.4',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            color: '#A1A1AF',
            marginLeft: "1.5rem"
        }
    }

    const addressStyles = {
        block: {
            background: "#121317",
            padding: isMobile ? "20px" : "60px",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "40px",
        },
    }


    return (
        <div>
            <Squircle cornerRadius={30} className='inner-block' style={addressStyles?.block} >
                {/* <div style={addressStyles?.block}> */}
                <AddressForm
                    title="Shipping Address"
                    addressData={shippingAddress?.data?.value}
                    onChange={handleShippingAddressChange}
                    countries={countriesList}
                    states={selectedShippingCountry?.id ? getFilteredStates(selectedShippingCountry?.id) : []}
                    cities={selectedShippingState?.id ? getFilteredCities(selectedShippingCountry?.id, selectedShippingState?.id) : []}
                    selectedCountry={selectedShippingCountry}
                    selectedState={selectedShippingState}
                    selectedCity={selectedShippingCity}
                    isCountryChanged={isCountryChanged}
                    onCountryChange={(value) => {
                        setSelectedShippingCountry(value);
                        setSelectedShippingState(null)
                        setSelectedShippingCity(null)
                        handleShippingAddressChange('country', value?.name);
                        setIsCountryChanged(true);

                        setTimeout(() => {
                            setIsCountryChanged(false);
                        }, 1000);
                    }}
                    isStateChanged={isStateChanged}
                    onStateChange={(value) => {
                        setSelectedShippingState(value);
                        setSelectedShippingCity(null)
                        handleShippingAddressChange('state', value?.name);
                        setIsStateChanged(true);

                        setTimeout(() => {
                            setIsStateChanged(false);
                        }, 1000);
                    }}
                    isCityChanged={isCityChanged}
                    onCityChange={(value) => {
                        setSelectedShippingCity(value);
                        handleShippingAddressChange('city', value?.name);
                        setIsCityChanged(true);

                        setTimeout(() => {
                            setIsCityChanged(false);
                        }, 1000);
                    }}
                />

                <section className="address-details-block">
                    <div className='text-start d-flex align-items-center'>
                        <FontAwesomeIcon
                            icon={isSameAddress ? faCircleCheck : faCircle}
                            style={{
                                color: isSameAddress ? "#71F0FF" : "#A1A1AF",
                                fontSize: "18.59px",
                                borderRadius: "50%",
                            }}
                            onClick={handleAddressRadioButtonChange}
                        />

                        <FormControlLabel
                            sx={formRadioLabelStyle}
                            control={<Radio checked={isSameAddress} onClick={handleAddressRadioButtonChange} />}
                            label="Billing address as shipping address"
                        />
                    </div>

                    {isSameAddress && (
                        <>
                            <div className='text-start d-flex align-items-center mt-4'>
                                <FontAwesomeIcon
                                    icon={isWhatsappConsent ? faCircleCheck : faCircle}
                                    style={{
                                        color: isWhatsappConsent ? "#71F0FF" : "#A1A1AF",
                                        fontSize: "18.59px",
                                        borderRadius: "50%",
                                    }}
                                    onClick={handleWhatsappConsentChange}
                                />

                                <FormControlLabel
                                    sx={formRadioLabelStyle1}
                                    control={<Radio checked={isWhatsappConsent} onClick={handleWhatsappConsentChange} />}
                                    label="I agree to receive shipping updates and important notifications via WhatsApp."
                                />

                            </div>
                            <div className='text-start'>
                                <Button
                                    variant="contained"
                                    size='small'
                                    color='success'
                                    sx={isSubmitDisabled ? commonStyles?.button?.disabled : commonStyles?.button?.contained}
                                    disabled={isSubmitDisabled}
                                    onClick={() => {
                                        handleSubmitResponse()
                                    }}
                                >
                                    <span>{"Submit"}</span>
                                </Button>
                            </div>
                        </>
                    )}

                </section>

                {/* </div> */}
            </Squircle>
            {/*  */}

            {!isSameAddress && (
                <Squircle cornerRadius={30} className='inner-block mt-5' style={addressStyles?.block} >
                    {/* <div className='inner-block mt-5' style={addressStyles?.block}> */}
                    <AddressForm
                        title="Billing Address"
                        addressData={billingAddress?.data?.value}
                        onChange={handleBillingAddressChange}
                        countries={countriesList}
                        states={selectedBillingCountry?.id ? getFilteredStates(selectedBillingCountry?.id) : []}
                        cities={selectedBillingState?.id ? getFilteredCities(selectedBillingCountry?.id, selectedBillingState?.id) : []}
                        selectedCountry={selectedBillingCountry}
                        selectedState={selectedBillingState}
                        selectedCity={selectedBillingCity}
                        onCountryChange={(value) => {
                            setSelectedBillingCountry(value);
                            setSelectedBillingState(null);
                            setSelectedBillingCity(null)
                            handleBillingAddressChange('country', value?.name);
                        }}
                        onStateChange={(value) => {
                            setSelectedBillingState(value);
                            setSelectedBillingCity(null)
                            handleBillingAddressChange('state', value?.name);
                        }}
                        onCityChange={(value) => {
                            setSelectedBillingCity(value);
                            handleBillingAddressChange('city', value?.name);
                        }}
                    />
                    <section className='address-details-block'>
                        <div className='text-start  d-flex align-items-center mt-2'>
                            <FontAwesomeIcon
                                icon={isWhatsappConsent ? faCircleCheck : faCircle}
                                style={{
                                    color: isWhatsappConsent ? "#71F0FF" : "#A1A1AF",
                                    fontSize: "18.59px",
                                    borderRadius: "50%",
                                }}
                                onClick={handleWhatsappConsentChange}
                            />
                            <FormControlLabel
                                sx={formRadioLabelStyle1}
                                control={<Radio checked={isWhatsappConsent} onClick={handleWhatsappConsentChange} />}
                                label="I agree to receive shipping updates and important notifications via WhatsApp."
                            />
                        </div>
                        <div className='text-start'>
                            <Button
                                variant="contained"
                                size='small'
                                color='success'
                                sx={isSubmitDisabled ? commonStyles?.button?.disabled : commonStyles?.button?.contained}
                                disabled={isSubmitDisabled}
                                onClick={() => {
                                    handleSubmitResponse()
                                }}
                            >
                                <span>{"Submit"}</span>
                            </Button>
                        </div>
                    </section>
                    {/* </div> */}
                </Squircle>
            )}
        </div>
    )
}

export default AddressQuestions