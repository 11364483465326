import { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import paymentQuestionsJson from '../components/json/payment_questions.json';
import { handleGetApiCall } from "../api-functions/CommonApiCall";
import { getLeadDetailsURL } from "../api-functions/ApiURLs";
import { setAmplitudeUserID, startAmplitudeSession } from "../components/common/Amplitute";
import screeningQuestionsJson from '../components/json/screening_questions.json';
import Cookies from "universal-cookie";

const MainContext = createContext({});

export const MainContextProvider = ({ children }) => {
  const cookies = new Cookies();

  const [isPreLoading, setIsPreLoading] = useState(false); // Preloading Other

  const [isWaitlistFormSubmitted, setIsWaitlistFormSubmitted] = useState(false)

  const [paymentQuestionnaireObject, setPaymentQuestionnaireObject] = useState(paymentQuestionsJson);

  // const [leadId, setLeadId] = useState("706ef0ee-e417-4a40-8fdd-fdc5dffc461b" || null); 
  const [leadId, setLeadId] = useState(null);
  const [leadInfo, setLeadInfo] = useState(null);
  const [leadIsAlreadyPaid, setLeadIsAlreadyPaid] = useState(false);

  const [leadEligibility, setLeadEligibility] = useState(null);   
  const [disclaimerState, setDisclaimerState] = useState(null);

  const [screeningQuestionnaireAnswers, setScreeningQuestionnaireAnswers] = useState(null); 
  const [questionnaireObject, setQuestionnaireObject] = useState(screeningQuestionsJson); 
  const [currentQuestionId, setCurrentQuestionId] = useState(1);
  const [answeredQuestionsStack, setAnsweredQuestionsStack] = useState([]);

  const [isWhatsappConsent, setIsWhatsappConsent] = useState(true);

  /* OTP */

  const [isSendOTP, setIsSendOTP] = useState(false);
  const [hashedMobile, setHashedMobile] = useState(null);

  const [otpVerificationObject, setOtpVerificationObject] = useState([]);
 
  const ShowNotification = (type, message) => {
    toast[type](message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  
  useEffect(() => {
    const fetchLeadDetails = async () => {
      try {
        let payload = {
          lead_id: leadId
        }
        const response = await handleGetApiCall(getLeadDetailsURL, payload);
        let responseData = response?.data?.lead;
        // let countryCodeUpdate = responseData?.country_code?.replace('+', '');
        setIsWhatsappConsent(responseData?.billing_address ? responseData?.is_whatsapp_consent : true)
        setLeadInfo(responseData)
        setLeadEligibility(responseData?.is_eligible)
        
        if(response?.data?.is_paid){
          setLeadIsAlreadyPaid(true);
        }

      } catch (error) {
        console.error("Error fetching data:", error);
        ShowNotification("info", error?.errorMessage)
      } finally {
      }
    }

    if (leadId) {
      fetchLeadDetails()
      setAmplitudeUserID(leadId)
    }
  }, [leadId])

  useEffect(()=>{
    startAmplitudeSession();
  },[])

  // Access Token Setting
  // useEffect(() => {
  //   const tokenID = cookies.get("lead_id");
  //   if (tokenID) {
  //     setLeadId(tokenID)
  //   }
  // }, []);

  /*  */

  const [isBetaUserDiscount, setIsBetaUserDiscount] = useState(null);
  const [couponCode, setCouponCode] = useState(null);
  const [couponCodeResponse, setCouponCodeResponse] = useState(null);
  
  return (
    <MainContext.Provider
      value={{
        ShowNotification,
        isPreLoading,
        setIsPreLoading,
        leadIsAlreadyPaid,
        isWaitlistFormSubmitted, setIsWaitlistFormSubmitted,
        leadId, setLeadId,
        leadInfo, setLeadInfo,
        leadEligibility, setLeadEligibility,
        disclaimerState, setDisclaimerState,

        isSendOTP, setIsSendOTP,
        hashedMobile, setHashedMobile,
        otpVerificationObject, setOtpVerificationObject,
        paymentQuestionnaireObject, setPaymentQuestionnaireObject,
        screeningQuestionnaireAnswers, setScreeningQuestionnaireAnswers,
        questionnaireObject, setQuestionnaireObject,
        currentQuestionId, setCurrentQuestionId,
        answeredQuestionsStack, setAnsweredQuestionsStack,

        isBetaUserDiscount, setIsBetaUserDiscount,
        couponCode, setCouponCode,
        couponCodeResponse, setCouponCodeResponse,
        isWhatsappConsent, setIsWhatsappConsent
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export default MainContext;
