import { TextField, useMediaQuery } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

const InputTextField = ({value, onChange, name, errorState, suggestionName }) => {

    const isMobile = useMediaQuery("(max-width: 768px)");

    const textFieldStyles = {
        '.MuiOutlinedInput-root': {
            borderRadius: '12px',
            border: '1px solid #35394A',
            height: isMobile ? "47px" : "55px",
            padding: isMobile ? '16px 0px' : '18px 0px',
            '&:hover': {
                borderColor: '#AFAFBB',  // Hover color
                '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                    '&:hover': {
                        borderColor: 'transparent',
                    },
                },
            },
            '&.Mui-error':{
                '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                    '&:hover': {
                        borderColor: 'transparent',
                    },
                },
                borderColor: '#FF6E66' // Error color
            },
            '&.Mui-focused': {
                borderColor: '#AFAFBB',  // Focus color
                '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                    '&:hover': {
                        borderColor: 'transparent',
                    },
                },
            },
            '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
                '&:hover': {
                    borderColor: 'transparent',
                },
            },
            '&.MuiOutlinedInput-input': {
                color: "#D3D3DA"
            }, 
            input: {
                padding: '16px 20px',
                borderRadius: '12px',
                fontSize: '16px',
                fontWeight: 400,
                color: '#D3D3DA',
                lineHeight: '19.2px',
                '&:-webkit-autofill': {
                    appearence: "none",
                    backgroundColor: '#121317 !important',
                    WebkitBoxShadow: '0 0 0px 60px #121317 inset !important',
                    WebkitTextFillColor: '#D3D3DA !important',
                    color: '#D3D3DA !important',
                    padding: isMobile ? "14px" : "15px",
                    border: "none"
                },
                '&:-internal-autofill-selected': {
                    appearence: "none",
                    backgroundColor: '#121317 !important', 
                    WebkitBoxShadow: '0 0 0px 60px #121317 inset !important',
                    WebkitTextFillColor: '#D3D3DA !important',
                    color: '#D3D3DA !important',
                    padding: isMobile ? "14px" : "15px",
                    border: "none"
                },
            },
        },
        '@media (max-width: 768px)': {
            '.MuiOutlinedInput-root': {
                height: isMobile ? "47px" : "55px",
                input: {
                    fontSize: '16px',
                },
            },
        },
    };

    return (
        <TextField
            label=""
            type="text"
            size="small"
            // name={uuidv4() || name}
            name={suggestionName || uuidv4()}
            placeholder=""
            fullWidth
            variant="outlined"
            value={value}
            onChange={(e) => onChange(name, e.target.value)}
            sx={textFieldStyles}
            error={errorState}
            // autoComplete="off"
            // onFocus={(e)=>{
            //     e.preventDefault()
            // }}     
        />
    );
};

export default InputTextField;
