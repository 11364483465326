import { InputLabel, useMediaQuery } from "@mui/material";


const InputLabelField = ({ label, htmlFor, isRequired }) => {
    
    const isMobile = useMediaQuery("(max-width: 768px)");

    const labelFieldStyles = {
        fontSize: isMobile ? '12px' : '14px',
        fontWeight: 500,
        lineHeight: '16.8px',
        color: '#A1A1AF',
        marginBottom: '0.5rem',
        textAlign: "left",
        '& span': {
            color: '#FF564D',  // Custom color for span
        },
    };

    return (
        <InputLabel
            htmlFor={htmlFor}
            sx={labelFieldStyles}
        >
            {label} {isRequired && <span>*</span>}
        </InputLabel>
    );
};

export default InputLabelField;
