import React from "react";
import Select from "react-select";
import RadioButton from '../../../assets/svg/RadioButton.svg';
import RadioButtonEnabled from '../../../assets/svg/RadioButtonEnabled.svg';

const AutoCompleteSelect = ({ optionsData, selectedOption, onChange, changeState, type }) => {

    const autoCompleteCustomStyles = {
        control: (provided, state) => ({
            ...provided,
            height: "55px",
            backgroundColor: "#121317",
            borderColor: state.isFocused ? "#AFAFBB" : "#35394A",
            borderRadius: "12px",
            color: "white",
            padding: "6px",
            boxShadow: state.isFocused ? "none" : "none",
            "&:hover": {
                borderColor: "#AFAFBB",
            },
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: "#121317",
        }),
        option: (provided, { isSelected, isFocused }) => ({
            ...provided,
            display: "flex",
            alignItems: "center",
            backgroundColor: isSelected ? "#313131" : isFocused ? "#232323" : "#121317",
            cursor: "pointer",
            color: "white",
            padding: "10px 4px",
            fontSize: "16px",
            // borderBottom: "1px solid #3D3D48"
        }),
        input: (provided) => ({
            ...provided,
            color: "white",
            fontSize: "16px"
        }),
        singleValue: (provided) => ({
            ...provided,
            color: "white",
            display: "flex",
            alignItems: "center",
        }),
    };

    const options = optionsData?.map((data) => ({
        value: data.name,
        label: (
            <section style={{ width: "100%" }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                    }}>
                    <div>
                        {type === "country" && (
                            <img
                                src={`https://flagcdn.com/w40/${data?.iso2?.toLowerCase()}.png`}
                                alt={data.name}
                                style={{ width: "19px", height: "auto", borderRadius: "2px", marginRight: "12px" }}
                            />)}
                        <span>{data.name}</span>
                    </div>
                    <img
                        src={selectedOption?.name === data.name ? RadioButtonEnabled : RadioButton}
                        style={{ width: "20px" }}
                        alt="checkboxButton"
                    />
                </div>
            </section>
        ),
        displayLabel: data.name,
    }));

    const handleMenuOpen = () => {
        setTimeout(() => {
            const menuList = document.querySelector(".auto-complete-select__menu-list");
            const selectedOptionEl = document.querySelector(".auto-complete-select__option--is-selected");

            if (menuList && selectedOptionEl) {
                menuList.scrollTop = selectedOptionEl.offsetTop - menuList.offsetTop;
            }
        }, 10)
    };

    return (
        <Select
            className={`auto-complete-select ${changeState ? "selected" : ""}`}
            classNamePrefix="auto-complete-select"
            styles={autoCompleteCustomStyles}
            options={options}
            value={options.find((opt) => opt.value === selectedOption?.name) || null}
            // getOptionLabel={(e) => e.displayLabel}
            onChange={(selected) => {
                let filterSelectedOption = optionsData.find((c) => c.name === selected.value);
                onChange(filterSelectedOption)
            }}
            isSearchable
            onMenuOpen={handleMenuOpen}
            placeholder=""
        />
    );
};

export default AutoCompleteSelect;
