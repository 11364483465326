import { colors, Container, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect } from 'react'
import { Link } from 'react-router'
import { trackExistingCustomerEvent } from '../common/Amplitute'

const ExistingCustomer = () => {
    const isMobile = useMediaQuery("(max-width:768px)");

    useEffect(()=>{
        trackExistingCustomerEvent()
    },[])
    
    return (
        <Container maxWidth="lg" sx={{minHeight: isMobile ? "calc(100vh - 100px)" : "calc(100vh - 60px)"}}>
            <div style={{ 
                minHeight: "calc(100vh - 90px)", 
                width: "100%", 
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                justifyContent: "center"}}>
                <div>
                    <Typography sx={{ color: "#D3D3DA", fontSize: "20px", lineHeight: "32px", marginBottom: "1rem" }}>
                        Looks like you already have an account with Deepholistics. Please continue
                        your journey in the App.
                    </Typography>
                    <Typography sx={{ color: "#D3D3DA", fontSize: "20px", lineHeight: "32px" }}>
                        If you do require any assistance, you can contact support{" "}
                        <a
                            style={{ color: "#70ACFF" }}
                            href="http://deepholistics.com/support"
                            target="_blank"
                            underline="none"
                        >
                            here
                        </a>{" "}
                        or leave us an email at{" "}
                        <a style={{ color: "#70ACFF" }} href="mailto:support@deepholistics.com" underline="none">
                            support@deepholistics.com
                        </a>
                        .

                    </Typography>
                </div>
        </div>
        </Container>
    )
}

export default ExistingCustomer