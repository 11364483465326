import Grid from '@mui/material/Grid2';
import React, { useContext, useEffect } from 'react'
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, useMediaQuery } from '@mui/material';
import MainContext from '../../context/MainContext';
import { handleGetApiCall, handlePostApiCall } from '../../api-functions/CommonApiCall';
import { checkRazorpayPaymentURL, getRazorpayOrderURL, verifyCouponURL } from '../../api-functions/ApiURLs';
import { useNavigate } from 'react-router';
import LocationIcon from '../../assets/svg/location.svg'
import PhoneIcon from '../../assets/svg/phone.svg'
import { trackCompletedPaymentEvent, trackStartPaymentEvent } from '../common/Amplitute';
import { commonStyles } from '../common/elements/commonStyle';
import { formatPhoneNumber } from '../common/CommonFunctions';
import { Squircle } from 'corner-smoothing';

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

const Payment = () => {

    const navigate = useNavigate();

    const { setIsPreLoading, ShowNotification, leadId, leadInfo, couponCode, couponCodeResponse, setCouponCodeResponse, isBetaUserDiscount, setIsBetaUserDiscount } = useContext(MainContext)

    const { REACT_APP_RAZORPAY_KEY } = process.env;

    const isMobile = useMediaQuery("(max-width:768px)");

    useEffect(() => {
        if (couponCode) {
            handleVerifyCoupon()
        }
        console.log(isMobile)
    }, [])

    const handleVerifyCoupon = async () => {
        let requestData = {
            lead_id: leadId,
            code: couponCode,
        };

        if (couponCode) {
            try {
                setIsPreLoading(true)
                const verifyCouponResponse = await handlePostApiCall(verifyCouponURL, requestData);

                const responseData = verifyCouponResponse?.data;
                setCouponCodeResponse(responseData);
                if (responseData?.is_coupon_code_valid && responseData?.discount_percentage === "100") {
                    setIsBetaUserDiscount(true);
                }

            } catch (error) {
                console.error(
                    "Error fetching data:",
                    error.response ? error.response.data : error.message
                );
            } finally {
                setIsPreLoading(false)
            }
        }
    };

    const getOrderData = async () => {

        trackStartPaymentEvent()
        try {
            setIsPreLoading(true)
            let payload = {
                lead_id: leadId,
                // coupon_code: null,
                coupon_code: couponCodeResponse?.is_coupon_code_valid
                    ? couponCode
                    : null,
            };

            const getRazorepayOrderResponse = await handlePostApiCall(getRazorpayOrderURL, payload);

            if (getRazorepayOrderResponse?.status === "success") {
                return getRazorepayOrderResponse?.data;
            }
        } catch (error) {
            console.error(
                "Error fetching data:",
                error.response ? error.response.data : error.message
            );
            ShowNotification("error", error.message || "Unable to get Payment order");
        } finally {
            setIsPreLoading(false)
        }
    }

    const handleCheckRazorpayPayment = async (orderID) => {
        setIsPreLoading(true);
        try {
            let payload = {
                payment_order_id: orderID,
            };

            const checkRazorepayPaymentResponse = await handleGetApiCall(checkRazorpayPaymentURL, payload);

            if (checkRazorepayPaymentResponse?.status === "success") {
                if (checkRazorepayPaymentResponse?.data?.status === "SUCCESS") {
                    trackCompletedPaymentEvent()
                    navigate("/complete")
                }
            }
        } catch (error) {
            console.error(
                "Error fetching data:",
                error.response ? error.response.data : error.message
            );
            throw error;
        } finally {
            setIsPreLoading(false);
        }
    };

    const displayRazorpay = async () => {

        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Raxor Pay SDK Failed...");
            return;
        }

        try {
            const orderData = await getOrderData();

            if (
                couponCodeResponse?.is_coupon_code_valid &&
                couponCodeResponse?.discount_percentage === "100"
            ) {
                handleCheckRazorpayPayment(orderData.payment_order_id); //ByPass Payment for 100% Disc.
            } else {

                const options = {
                    key: REACT_APP_RAZORPAY_KEY, // RazorPay Key,
                    amount: orderData.amount.toString(),
                    currency: orderData.currency,
                    order_id: orderData.payment_order_id,
                    name: "Deep Holistics",
                    description: "Payment for Deepholistics",
                    image:
                        "https://deepholisticsstaticfile.blob.core.windows.net/images/deep_holistics/logo/dh-logo-dark.png",
                    handler: function (response) {
                        if (
                            response.razorpay_order_id &&
                            response.razorpay_payment_id &&
                            response.razorpay_signature
                        ) {
                            handleCheckRazorpayPayment(response.razorpay_order_id);
                        } else {
                            console.info("razorpay-Success", response);
                        }
                    },
                    prefill: {
                        name: leadInfo?.name,
                        contact: leadInfo?.mobile,
                        email: leadInfo?.email,
                    },
                    config: {
                        display: {
                            // hide: [
                            //     {
                            //         method: "upi",
                            //     },
                            //     {
                            //         method: "wallet",
                            //     },
                            // ],
                        },
                    },
                    method: "netbanking",
                    theme: {
                        color: "#3399cc",
                    },
                };

                const paymentobject = new window.Razorpay(options);
                paymentobject.open();

                paymentobject.on("payment.failed", function (response) {
                    console.info("razorpay-failure", response);
                });
            }

        } catch (error) {
            console.error("error", error.message);
        }
    }

    const paymentStyles = {
        block: {
            background: "#121317",
            padding: isMobile ? "20px" : "60px",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "40px",
        },
        name: {
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: '26.52px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            color: '#D3D3DA',
            marginTop: "0",
            marginBottom: '1rem',
        },
        labelText: {
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: '23.22px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            marginBottom: '1.5rem',
            color: "#A1A1AF"
        },
        valueText: {
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: '23.22px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            marginBottom: '1.5rem',
            color: "#D3D3DA"
        },
        mobilenumber: {
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: '19.2px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            // color: '#D3D3DA',
            marginTop: '0.5rem',
            marginBottom: '0.75rem',
            color: "#F1F3F6"
        },
    }

    return (
        <Squircle cornerRadius={30} className='inner-block' style={paymentStyles?.block} >
            {/* <div className='inner-block' style={paymentStyles?.block}> */}
            <section className='submission-block'>
                <p style={paymentStyles?.name}>{leadInfo?.name}</p>
                <Grid container spacing={2}>
                    <Grid size={{ md: 5 }}>
                        <p style={paymentStyles?.labelText} >
                            <img src={PhoneIcon} style={{ width: "16px", marginRight: "0.5rem" }} />
                            Contact Number:

                            {isMobile && (
                                <span style={paymentStyles?.valueText}>
                                    {leadInfo?.country_code && leadInfo?.mobile && (
                                        ` +${formatPhoneNumber(leadInfo.country_code, leadInfo.mobile)}`
                                    )}
                                </span>
                            )}
                        </p>
                        <p
                            style={{
                                ...paymentStyles?.labelText,
                                color: "#AFAFBB"
                            }}
                        >
                            <img src={LocationIcon} style={{ width: "16px", marginRight: "0.5rem" }} />
                            Shipping Address:
                            {isMobile && (
                                <span
                                    style={{
                                        ...paymentStyles?.valueText,
                                        color: "#D3D3DA",
                                        marginLeft: "0.5rem"
                                    }}
                                >
                                    {leadInfo?.communication_address?.address}
                                </span>
                            )}
                        </p>

                        {isMobile && (
                            <p
                                style={{
                                    ...paymentStyles?.labelText,
                                    color: "#71F0FF",
                                    cursor: "pointer",
                                    textDecoration: "underline"
                                }}
                                onClick={() => {
                                    navigate("/address-question")
                                }}
                            >
                                Edit
                            </p>
                        )}
                    </Grid>
                    {!isMobile && (
                        <Grid size={{ md: 7 }} className="desktop-view">
                            <p style={paymentStyles?.valueText}>
                                {leadInfo?.country_code && leadInfo?.mobile && (
                                    `+${formatPhoneNumber(leadInfo.country_code, leadInfo.mobile)}`
                                )}
                            </p>

                            <p
                                style={paymentStyles?.valueText}
                            >
                                {leadInfo?.communication_address?.address}
                            </p>

                            <p
                                style={{
                                    ...paymentStyles?.labelText,
                                    color: "#71F0FF",
                                    cursor: "pointer",
                                    textDecoration: "underline"
                                }}
                                onClick={() => {
                                    navigate("/address-question")
                                }}
                            >
                                Edit
                            </p>
                        </Grid>
                    )}
                </Grid>
                <div className='text-start'>
                    <Button
                        variant="contained"
                        size='small'
                        color='success'
                        sx={commonStyles?.button?.contained}
                        onClick={() => {
                            displayRazorpay();
                        }}
                    >
                        <span>{isBetaUserDiscount ? "Confirm" : "Proceed to pay"}</span>
                    </Button>
                </div>
            </section>
            {/* </div> */}
        </Squircle>
    )
}

export default Payment