import React, { useContext, useEffect, useRef, useState } from 'react'
import "./WaitlistForm.scss"
import Grid from '@mui/material/Grid2';
import { Button, Container, Divider, IconButton, InputBase, Paper, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router';
import { handlePostApiCall } from '../../api-functions/CommonApiCall';
import { registerLeadUrl } from '../../api-functions/ApiURLs';
import MainContext from '../../context/MainContext';
import countryJSON from '../json/countries_list.json';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { trackEnterCheckEligibilityEvent, trackWaitlistFormEnterEvent, trackWaitlistFormSubmissionEvent } from '../common/Amplitute';
import InputLabelField from '../common/elements/InputLabelField';
import InputTextField from '../common/elements/InputTextField';
import { commonStyles } from '../common/elements/commonStyle';
import CountrySelect from '../common/elements/CountrySelect';
import Cookies from 'universal-cookie';

const WaitlistForm = () => {
    const navigate = useNavigate();
    const cookies = new Cookies();

    const isMobile = useMediaQuery("(max-width: 768px)");

    const [leadName, setLeadName] = useState("")
    const [leadCountryCode, setLeadCountryCode] = useState("91")
    const [leadMobile, setLeadMobile] = useState("")
    const [leadEmail, setLeadEmail] = useState("")
    const [leadRefSource, setLeadRefSource] = useState("")

    // const [phoneNumberValue, setPhoneNumberValue] = useState(null)
    const [isInputFocused, setIsInputFocused] = useState(false);

    const [isSubmitAlert, setIsSubmitAlert] = useState(false); 
    const [isButtonDisabled, setIsButtonDisabled] = useState(true); 

    const [isValidationPhase, setIsValidationPhase] = useState(false);

    const { 
        setIsPreLoading,
        isWaitlistFormSubmitted, setIsWaitlistFormSubmitted, 
        setLeadId,
        setLeadInfo 
    } = useContext(MainContext)

    useEffect(()=>{
        trackWaitlistFormEnterEvent()
    },[])

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleRegisterLead = async () => {
        let payload = {
            name: leadName,
            email: leadEmail,
            country_code: leadCountryCode?.replace("+", ""),
            mobile: leadMobile,
            ref_source: leadRefSource
        };
        try {
            setIsPreLoading(true)
            const response = await handlePostApiCall(registerLeadUrl, payload);
            setIsWaitlistFormSubmitted(true);
            setLeadId(response?.data?.lead?.lead_id);
            cookies.set("lead_id", response?.data?.lead?.lead_id, {
                path: "/",
                maxAge: 604800,
            });
            setLeadInfo(response?.data?.lead || []);
        } catch (error) {
            console.error("error", error?.message);
        } finally {
            setIsPreLoading(false)
            trackWaitlistFormSubmissionEvent()
        }
    };

    const [showDropdown, setShowDropdown] = useState(false)
    const autocompleteRef = useRef(null);
    const waitlistFormRef = useRef(null);

    const handleClickOutside = (event) => {
        const isClickedautocompleteOption = event.target.closest('.MuiIconButton-root') ||
            event.target.closest('.MuiListItemText-primary') ||
            event.target.closest('.auto-complete-select') ||
            event.target.closest('.auto-complete-select__input-container') ||
            event.target.closest('.auto-complete-select__control');
        if (!isClickedautocompleteOption) {
            setShowDropdown(false);
        }

    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (!isValidationPhase) {
            const hasBasicValues =
                leadName?.trim() &&
                leadEmail &&
                leadCountryCode &&
                leadMobile &&
                leadRefSource;
    
            setIsButtonDisabled(!hasBasicValues);
        } else {
            const isValid =
                leadName?.trim() &&
                validateEmail(leadEmail) &&
                leadCountryCode &&
                leadMobile?.length >= 7 &&
                leadRefSource;
    
            setIsButtonDisabled(!isValid);
        }
    }, [leadName, leadMobile, leadEmail, leadCountryCode, leadRefSource, isValidationPhase]);

    const waitistStyles = {
        headerText: {
            fontSize: "40px",
            fontWeight: 400,
            lineHeight: '53.04px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            color: '#F1F3F6',
            marginBottom: '12px',
            '@media (max-width: 768px)': {
                fontSize: '32px',
                lineHeight: '38px',
            },
        },        
        description: {
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: '26.52px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            marginBottom: '2.5rem',
            color: "#C5C5CE"
        },
        errorText: {
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: '26.52px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            marginTop: "0.75rem",
            marginBottom: '1rem',
            color: "#FF6E66"
        },
        errorHelperText: {
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: '26.52px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            marginTop: "0.5rem",
            marginBottom: '1rem',
            color: "#FF6E66"
        },
        landingPage: {
            Header: {
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: '26.52px',
                textAlign: 'left',
                textUnderlinePosition: 'from-font',
                textDecorationSkipInk: 'none',
                color: '#71F0FF',
                marginBottom: '1.5rem',
                textTransform: "uppercase",
                letterSpacing: "calc(20px * 0.1)",
                '@media (max-width: 768px)': {
                    fontSize: '14px',
                    lineHeight: '24px',
                    letterSpacing: "calc(14px * 0.1)",
                },
            },
            description: {
                fontSize: "40px",
                fontWeight: 400,
                lineHeight: '53.04px',
                textAlign: 'left',
                textUnderlinePosition: 'from-font',
                textDecorationSkipInk: 'none',
                marginBottom: '1rem',
                color: "#E1E1E5",
                '@media (max-width: 768px)': {
                    fontSize: '24px',
                    lineHeight: '28px',
                },
            },
        },
        submissionSection :{
            display: "flex", 
            minHeight: "calc(100vh - 216px)", 
            alignItems: "center",
            '@media (max-width: 768px)': {
                alignItems: "top",
            },
        }
    }

    const handleNameChange = (key, value) => {
        const inputValue = value;
        if (/^[A-Za-z\s]*$/.test(inputValue)) {
            const cleanedValue = inputValue.replace(/\s{2,}/g, ' ');
            setLeadName(cleanedValue);
        }
    }

    const handleEmailChange = (key, value) => {
        const inputValue = value?.toLowerCase();
        setLeadEmail(inputValue)
    }

    const handleRefSouceChange = (key, value) => {
        setLeadRefSource(value)
    }

    const handleMobileChange = (value) => {
        const sanitizedValue = value.replace(/[^0-9]/g, "");
        const maxLength = 15;

        if (sanitizedValue.length <= maxLength) {
            setLeadMobile(sanitizedValue)
        }
    }

    const handleCountrySelect = (value) => {
        setSelectedCountry(value)
        setLeadCountryCode(value.phone_code);
        // setPhoneNumberValue(value)
        setShowDropdown(false)
    }

    const [selectedCountry, setSelectedCountry] = useState({
        "id": 101,
        "name": "India",
        "iso3": "IND",
        "iso2": "IN",
        "numeric_code": "356",
        "phone_code": "91",
        "capital": "New Delhi",
        "currency": "INR",
        "currency_name": "Indian rupee",
        "currency_symbol": "₹",
        "tld": ".in",
        "native": "भारत",
        "region": "Asia",
        "subregion": "Southern Asia",
        "latitude": "20.00000000",
        "longitude": "77.00000000",
        "emoji": "🇮🇳"
    })
    
    const selectRef = useRef(null);

    return (
        <Container maxWidth="lg" sx={{ minHeight: isMobile ? "calc(100vh - 100px)" : "calc(100vh - 60px)" }}
        >
            <section className='waitlist-form-container main-component-outer-block'>
                <Grid container spacing={2}>
                    {!isWaitlistFormSubmitted &&
                        <Grid size={{ md: 6, xs: 12 }}>
                            <div ref={waitlistFormRef}>
                                <Typography sx={waitistStyles?.headerText}>Join the Waitlist</Typography>
                                <Typography sx={waitistStyles?.description}>Welcome to being part of the 1%</Typography>

                                {isSubmitAlert && (leadName?.trim() === "" || leadRefSource?.trim() === "") &&
                                    <Typography sx={waitistStyles?.errorText}>*Required fields</Typography>
                                }

                                <div>
                                    <div className="mb-4 mt-4">
                                        <InputLabelField
                                            htmlFor={"name"}
                                            label={"Name"}
                                            isRequired={true}
                                        />
                                        <InputTextField
                                            value={leadName}
                                            onChange={handleNameChange}
                                            name="leadName"
                                            suggestionName="name"
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <InputLabelField
                                            htmlFor={"email"}
                                            label={"Email"}
                                            isRequired={true}
                                        />
                                        <InputTextField
                                            value={leadEmail}
                                            onChange={handleEmailChange}
                                            name="leadEmail"
                                            suggestionName="email"
                                        />
                                        {(!validateEmail(leadEmail) && isSubmitAlert) &&
                                            <Typography sx={waitistStyles?.errorHelperText}>* Please enter correct email</Typography>
                                        }
                                    </div>

                                    <div className="mb-4" style={{ position: "relative" }}>
                                        <InputLabelField
                                            htmlFor={"contact_number"}
                                            label={"Contact Number"}
                                            isRequired={true}
                                        />

                                        <Paper
                                            component="form"
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: "100%",
                                                height: "55px",
                                                borderRadius: "12px",
                                                padding: '0px',
                                                backgroundColor: "transparent",
                                                border: "1px solid #35394A",
                                                ...(showDropdown && {
                                                    borderBottomLeftRadius: 0,
                                                    borderBottomRightRadius: 0,
                                                    borderColor: "#AFAFBB",
                                                    borderBottom: "none",
                                                }),
                                                ...(isInputFocused && {
                                                    borderColor: "#AFAFBB",
                                                }),
                                            }}
                                        >
                                            <IconButton
                                                sx={{
                                                    p: '18px 20px',
                                                    color: "#D3D3DA",
                                                    fontSize: "16px"
                                                }}
                                                aria-label="menu"
                                                onClick={() => {
                                                    setShowDropdown(!showDropdown)
                                                    setTimeout(() => {
                                                        if (selectRef.current) {
                                                            selectRef.current.focus(); // Focus input
                                                        }
                                                    }, 1);
                                                }}
                                            >
                                                {`+${leadCountryCode}`} <KeyboardArrowDownOutlinedIcon />
                                            </IconButton>
                                            <Divider
                                                sx={{
                                                    height: "100%",
                                                    m: 0,
                                                    borderColor: "#35394A",
                                                    ...((isInputFocused || showDropdown) && {
                                                        borderColor: "#AFAFBB",
                                                    }),
                                                }}
                                                orientation="vertical"
                                            />
                                            <InputBase
                                                sx={{
                                                    ml: 1,
                                                    flex: 1,
                                                    border: "0px solid #35394A !important",
                                                    padding: '18px 20px 18px 8px',
                                                    borderRadius: '12px',
                                                    fontSize: '16px',
                                                    fontWeight: 400,
                                                    color: '#D3D3DA',
                                                    lineHeight: '19.2px',
                                                }}
                                                placeholder=""
                                                inputProps={{ 'aria-label': '' }}
                                                value={leadMobile}
                                                onChange={(e) => {
                                                    handleMobileChange(e.target?.value)
                                                }}
                                                onFocus={() => setIsInputFocused(true)}
                                                onBlur={() => setIsInputFocused(false)}
                                                onClick={() => {
                                                    setShowDropdown(false);
                                                }}
                                            />
                                        </Paper>

                                        {showDropdown && (
                                            <div ref={autocompleteRef}>
                                                <CountrySelect
                                                    optionsData={countryJSON}
                                                    selectedOption={selectedCountry}
                                                    onChange={handleCountrySelect}
                                                    selectRef={selectRef}
                                                    openDropdown={showDropdown}
                                                />
                                            </div>
                                        )}

                                        {(isSubmitAlert && leadMobile?.length < 7) &&
                                            <Typography sx={waitistStyles?.errorHelperText}>* Please enter correct contact number</Typography>
                                        }

                                    </div>

                                    <div className="mb-4">
                                        <InputLabelField
                                            htmlFor={"ref_source"}
                                            label={"How did you hear about us?"}
                                            isRequired={true}
                                        />
                                        <InputTextField
                                            value={leadRefSource}
                                            onChange={handleRefSouceChange}
                                            name="leadRefSource"
                                            errorState={leadRefSource?.trim() === "" && isSubmitAlert}
                                        />
                                    </div>

                                    <div className='text-start mt-3' >
                                        <Button
                                            variant="contained"
                                            sx={isButtonDisabled ? commonStyles?.button?.disabled : commonStyles?.button?.contained}
                                            disabled={isButtonDisabled}
                                            onClick={() => {
                                                setIsValidationPhase(true);
                                                setIsButtonDisabled(true);

                                                const isValid =
                                                    leadName?.trim() &&
                                                    validateEmail(leadEmail) &&
                                                    leadCountryCode &&
                                                    leadMobile?.length >= 7 &&
                                                    leadRefSource;

                                                if (!isValid) {
                                                    setIsSubmitAlert(true);
                                                    setIsButtonDisabled(false);
                                                } else {
                                                    handleRegisterLead();
                                                }
                                            }}
                                        >
                                            <span>Next</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    }

                    {isWaitlistFormSubmitted &&
                        <Grid size={{ md: 7.5, xs: 12 }}>
                            <div className='submission-section' style={waitistStyles?.landingPage?.submissionSection}>
                                <div>
                                    <Typography sx={waitistStyles?.landingPage?.Header}>Because every detail matters</Typography>
                                    <Typography sx={waitistStyles?.landingPage?.description}>Let<span style={{fontStyle:"oblique"}}>' </span>s begin with a few questions to better understand your health profile</Typography>

                                    <div className='text-start' >
                                        <Button
                                            variant="contained"
                                            sx={commonStyles?.button?.contained}
                                            onClick={() => {
                                                trackEnterCheckEligibilityEvent()
                                                navigate("/questionnaire-home");
                                            }}
                                        >
                                            Get Started
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    }

                </Grid>
            </section>
        </Container>
    )
}

export default WaitlistForm