import React, { useEffect, useRef } from 'react'
import Lottie from "lottie-web";
import PreLoaderJSON from "../json/loader.json";

const PreLoader = () => {
    const containerRef = useRef(null);

    useEffect(() => {
        const anim = Lottie.loadAnimation({
            container: containerRef.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: PreLoaderJSON,
        });

        anim.setSpeed(0.5);

        return () => {
            anim.destroy();
        };
    }, []);

    return <div 
                ref={containerRef} 
                className="preloader-lottie-animation" 
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    height: "100vh",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "rgb(0 0 0 / 70%)"
                  }}
            />;
}

export default PreLoader