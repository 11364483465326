import React, { useContext, useEffect } from 'react';
import { RadioButton } from 'primereact/radiobutton';
import './PaymentFlow.scss';
import MainContext from '../../context/MainContext';
import { useNavigate } from 'react-router';
import { trackAgreeTermsEvent, trackDisagreeTermsEvent } from '../common/Amplitute';
import { commonStyles } from '../common/elements/commonStyle';
import { useMediaQuery } from '@mui/material';
import { Squircle } from 'corner-smoothing';

const Disclaimer = () => {
    const isMobile = useMediaQuery("(max-width: 768px)");

    const navigate = useNavigate();
    const {
        leadIsAlreadyPaid,
        disclaimerState,
        setDisclaimerState,
        paymentQuestionnaireObject,
        setPaymentQuestionnaireObject,
        setIsPreLoading
    } = useContext(MainContext);

    useEffect(() => {
        console.log("leadIsAlreadyPaid", leadIsAlreadyPaid)
        if (leadIsAlreadyPaid) {
          setIsPreLoading(true);
          setTimeout(() => {
            navigate("/existing-customer");
            setIsPreLoading(false);
          }, 500);
        }
    }, [leadIsAlreadyPaid])


    const handleDisclaimerChange = (value) => {
        setPaymentQuestionnaireObject(
            paymentQuestionnaireObject.map((question) =>
                question?.id === 21 ? { ...question, data: { value } } : question
            )
        );
        setDisclaimerState(value);
        if (value) {
            trackAgreeTermsEvent()
            navigate('/otp-verification')
        } else {
            trackDisagreeTermsEvent()
        }
    };

    const radioButtonStyles = {
        radioOption: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            cursor: "pointer",
            label: (value) => ({
                width: "100%",
                height: "55px",
                padding: "18px 20px",
                gap: "24px",
                borderRadius: "12px",
                border: "1px solid #35394A",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "19.2px",
                textAign: "left",
                textUnderlinePosition: "from-font",
                textDecorationSkipInk: "none",
                color: value ? "#D3D3DA" : "#FF8680",
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",

                "&.disagreelabel": {
                    color: "#FF8680"
                }
            }),
        }
    }

    const renderRadioOption = (value, labelText) => (
        <div className={`radio-option`} style={radioButtonStyles?.radioOption}>
            <RadioButton
                inputId={value ? "true-button" : "false-button"}
                value={value}
                onChange={() => handleDisclaimerChange(value)}
                checked={disclaimerState === value}
                style={{display: "none"}}
            />
            <label 
                htmlFor={value ? "true-button" : "false-button"} 
                style={radioButtonStyles?.radioOption?.label(value)}
            >
                {labelText}
                {/* {disclaimerState === value && <FontAwesomeIcon icon={faCircleCheck} />} */}
            </label>
        </div>
    );

    const disclaimerStyles = {
        block: {
            background: "#121317",
            padding: isMobile ? "20px" : "60px",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "40px",
        },
        titleText: {
            fontSize: isMobile ? "16px" : "20px",
            fontWeight: 400,
            lineHeight: '26.52px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            color: '#D3D3DA',
            marginBottom: '10px',
            marginTop: '0',
        },
        description: {
            fontSize: isMobile ? "14px" : "16px",
            fontWeight: 400,
            lineHeight: isMobile ? "21px" : '23.22px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            color: '#A1A1AF',
            marginTop: '0',
            marginBottom: '1.5rem',
            color: "#A1A1AF"
        },
        disagreeText: {
            fontSize: isMobile ? "12px" : "14px",
            fontWeight: 400,
            lineHeight: isMobile ? "21px" : '23.22px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            color: '#D3D3DA',
            marginBottom: '1.5rem',
            color: "#FF8680",

            link: {
                textDecorationLine: "underline",
                textDecorationStyle: "solid",
                textUnderlinePosition: "from-font",
                textDecorationSkipInk: "auto",
                color: "#FF8680"
            },
        },
    }

    return (
        <Squircle cornerRadius={30} className='inner-block' style={disclaimerStyles?.block} >
            {/* <div className="inner-block" style={disclaimerStyles?.block}> */}
            <section className="disclaimer-block">
                <p style={disclaimerStyles?.titleText}>Disclaimer</p>
                <p style={disclaimerStyles?.description} >
                    I understand that while the Human Token offers individualized guidance on health, fitness, sleep,
                    nutrition, and supplements, it does not diagnose, identify, treat, prevent, or cure any illness. I
                    acknowledge the importance of consulting a healthcare provider before making any lifestyle changes.
                    By proceeding, I also agree to the{' '}
                    <a href="https://www.deepholistics.com/terms" target='_blank' style={commonStyles?.typography?.link} rel="noopener noreferrer">
                        terms of service
                    </a>{' '}
                    and{' '}
                    <a href="https://www.deepholistics.com/privacy" target='_blank' style={commonStyles?.typography?.link} rel="noopener noreferrer">
                        privacy policy
                    </a>.
                </p>

                <div className="d-flex flex-column gap-3 radio-button-options">
                    {renderRadioOption(true, 'Agree')}
                    {renderRadioOption(false, 'Disagree')}

                    {disclaimerState === false && (
                        <div style={disclaimerStyles?.disagreeText}>
                            <label >
                                To proceed with the program, we need you to agree to this disclaimer. If you have any
                                questions, please reach out at{' '}
                                <a href="mailto:support@deepholistics.com" style={disclaimerStyles?.disagreeText?.link}>
                                    support@deepholistics.com
                                </a>.
                            </label>
                        </div>
                    )}
                </div>
            </section>
            {/* </div> */}
        </Squircle>
    );
};

export default Disclaimer;
