import { Box, Container, createTheme, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CopyrightOutlinedIcon from '@mui/icons-material/CopyrightOutlined';
import React from 'react'
import { useTheme, ThemeProvider } from '@mui/material/styles';

const customTheme = createTheme({
    breakpoints: {
        values: {
            xxs: 0,
            xs: 300,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

const Footer = () => {

    const theme = useTheme();

    const commonStyles = {
        divider: {
            border: "1px solid #545463",
            marginBottom: "1rem",
        },
        typography: {
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "21px",
            color: "#C5C5CE",
            textDecoration: "none",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            '@media (max-width: 768px)': {
                justifyContent: 'center',
            },  
            
            link: {
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "21px",
                cursor: "pointer",
                color: "#C5C5CE",
                textDecoration: "none",
                '&:hover': {
                    textDecoration: "underline",
                },
            }            
        },
    };

    const currentYear = new Date().getFullYear();

    return (
        <ThemeProvider theme={customTheme}>
            <Container maxWidth="lg" sx={{minHeight: "unset"}}>
                <Divider sx={commonStyles.divider} />
                <Grid container spacing={2} sx={{ marginBottom: { xs: "1rem", md :"1rem" } }}>
                    <Grid item size={{ xs: 12, md: 6 }}>
                        <Box sx={{
                            display: "flex",
                            gap: "2rem",
                            justifyContent: {
                                xs: "center",
                                sm: "center",
                                md: "flex-start",
                                lg: "flex-start",
                            },
                        }}>
                            <Typography variant="subtitle1" sx={commonStyles.typography?.link}>
                                <a style={commonStyles.typography} href="https://www.deepholistics.com/terms" target='_blank'>
                                    Terms of service
                                </a>
                            </Typography>
                            <Typography variant="subtitle1" sx={commonStyles.typography?.link}>
                                <a style={commonStyles.typography} href="https://www.deepholistics.com/privacy" target='_blank'>
                                    Privacy policy
                                </a>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item size={{ xs: 12, md: 6 }} sx={{ textAlign: { xs: "center", md: "end" } }}>
                        <Typography variant="subtitle1" sx={commonStyles.typography}>
                            <CopyrightOutlinedIcon
                                sx={{
                                    color: "#545463",
                                    width: "20px",
                                    height: "20px",
                                    marginRight: "4px"
                                }} />
                            {currentYear} Deep Holistics. All rights reserved
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    )
}

export default Footer