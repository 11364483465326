export const registerLeadUrl = "v3/human-token/lead";
export const checkEligibilityURL = "v3/human-token/lead/eligibility";
export const sendScheduledEventCallbackURL = "v3/human-token/lead/email/mark-sent";

export const leadAddressUpdateURL = "v3/human-token/lead/address";

export const sendOTPURL = "v3/human-token/users/send-otp";
export const verifyOTPURL = "v3/human-token/users/verify-otp";

export const getLeadDetailsURL = "v3/human-token/lead/get";
export const getLeadUserCheckURL = "v3/human-token/users/check";

export const verifyCouponURL = "v3/human-token/coupon/code/check";
export const getRazorpayOrderURL = "v3/human-token/payments/orders";
export const checkRazorpayPaymentURL = "v3/human-token/payments/orders";