import React, { useContext, useEffect, useState } from 'react'
import OTPInput from "react-otp-input";
import './PaymentFlow.scss';
import { Button, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import MainContext from '../../context/MainContext';
import { handlePostApiCall } from '../../api-functions/CommonApiCall';
import { getLeadUserCheckURL, sendOTPURL, verifyOTPURL } from '../../api-functions/ApiURLs';
import { useNavigate } from 'react-router';
import { trackSendOTPEvent, trackVerificationCompletedEvent, trackVerifyOTPEvent } from '../common/Amplitute';
import { formatPhoneNumber } from '../common/CommonFunctions';
import { commonStyles } from '../common/elements/commonStyle';
import { Squircle } from 'corner-smoothing';

const Verification = () => {

    const isMobile = useMediaQuery("(max-width: 768px)");


    const { setIsPreLoading, leadInfo, 
            isSendOTP, setIsSendOTP, 
            hashedMobile, setHashedMobile, 
            otpVerificationObject, setOtpVerificationObject,
            // isBetaUserDiscount, 
            setIsBetaUserDiscount,
            // verifyOTPResponse, setVerifyOTPResponse,
            // couponCode, 
            setCouponCode,
            paymentQuestionnaireObject, setPaymentQuestionnaireObject
     } = useContext(MainContext);

    const navigate = useNavigate();

    const [otpTimeLeft, setOtpTimeLeft] = useState(null);
    const [otpTimeOut, setOtpTimeOut] = useState(true);

    const [isIncorrectOTP, setIsIncorrectOTP] = useState(false);

    const [otpInput, setOtpInput] = useState();

    useEffect(() => {
        if (!otpTimeLeft) {
            setOtpTimeOut(true);
            return;
        }
        const timer = setInterval(() => {
            setOtpTimeLeft(otpTimeLeft - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, [otpTimeLeft]);

    useEffect(()=>{
        if(otpInput?.length === 4){
            handleVerifyOTP()
        }
    }, [otpInput]);

    const formattedTime = `${Math.floor(otpTimeLeft / 60)}:${(otpTimeLeft % 60 < 10 ? '0' : '')}${otpTimeLeft % 60}`;

    /* Update Address for Pii User */
    const updateAddresses = (questions, htLead) => {
        return questions?.map((question) => {
            // Update question with id: 22 (shipping address)
            if (question?.id === 22) {
                return {
                    ...question,
                    data: {
                        value: {
                            address_line1: htLead?.communication_address?.address_line_1,
                            address_line2: htLead?.communication_address?.address_line_2,
                            city: htLead?.communication_address?.city,
                            state: htLead?.communication_address?.state,
                            country: htLead?.communication_address?.country,
                            zipcode: htLead?.communication_address?.pincode,
                        },
                    },
                };
            }
            // Update question with id: 23 (address state)
            if (question?.id === 23) {
                return {
                    ...question,
                    data: {
                        value: htLead?.billing_address?.address_line_1 ? "no" : "yes"
                    }
                }
            }
            // Update question with id: 24 (billing address)
            if (question?.id === 24) {
                return {
                    ...question,
                    data: {
                        value: {
                            address_line1: htLead?.billing_address?.address_line_1,
                            address_line2: htLead?.billing_address?.address_line_2,
                            city: htLead?.billing_address?.city,
                            state: htLead?.billing_address?.state,
                            country: htLead?.billing_address?.country,
                            zipcode: htLead?.billing_address?.pincode,
                        },
                    },
                };
            }

            return question;
        });
    };

    const handleCheckUser = async (type) => {

        if (leadInfo?.mobile && leadInfo?.name?.trim() && leadInfo?.country_code) {
            let payload = {
                country_code: leadInfo?.country_code,
                mobile: leadInfo?.mobile,
                lead_id: leadInfo?.lead_id,
                name: leadInfo?.name,
                lead_source: "website" || leadInfo?.ref_source,
                is_whatsapp_consent: leadInfo?.is_whatsapp_consent ?? true,
            };

            try {
                setIsPreLoading(true)
                await handlePostApiCall(getLeadUserCheckURL, payload)
                // let responseLeadID = response?.data?.lead_id;
                await handleSendOTP();
            } catch (error) {
                console.error("Error fetching data:", error.response ? error.response.data : error.message);
            } finally {
                setIsPreLoading(false)
            }
        } else {
        }
    };

    const handleSendOTP = async () => {
        
        let payload = {
            country_code: leadInfo?.country_code,
            mobile: leadInfo?.mobile,
            lead_id: leadInfo?.lead_id,
        };

        try {
            setIsPreLoading(true);
            const response = await handlePostApiCall(sendOTPURL, payload);

            if (response?.status === "success") {

                setHashedMobile(response?.data?.mh);
                setIsSendOTP(true);

                setTimeout(() => {
                    setOtpTimeOut(false);
                    setOtpTimeLeft(180);
                }, 900);

            } else if (response?.status === "error") {
                console.error("error", "Unable Send OTP");
            }
        } catch (error) {
            console.error("Error fetching data:", error.response ? error.response.data : error.message);
        } finally {
            setIsPreLoading(false)
            trackSendOTPEvent()
        }

    };

    const handleVerifyOTP = async () => {

        let payload = {
            otp: otpInput,
            mh: hashedMobile
        };

        try {
            setIsPreLoading(true);
            const response = await handlePostApiCall(verifyOTPURL, payload);

            let verifyResponseData = response?.data
            setOtpVerificationObject(verifyResponseData)
            setOtpTimeOut(true);
            setIsIncorrectOTP(false)

            setIsBetaUserDiscount(verifyResponseData?.is_free_user ? true : false) //set for Beta Users
            setCouponCode(verifyResponseData?.ht_coupon?.code || null); 
            
            trackVerifyOTPEvent()

            setTimeout(() => {
                handleNavigation(verifyResponseData)
            }, 1000);
        } catch (error) {

            console.error("Error fetching data:", error.response ? error.response.data : error.message);
            setIsIncorrectOTP(true)
            // setOtpInput(otpInput)
        } finally {
            setIsPreLoading(false)
        }
    };

    const handleNavigation = (otpVerificationObject=otpVerificationObject) => {

        if (otpVerificationObject?.pii_user?.communication_address) {
            const updatedQuestions = updateAddresses(paymentQuestionnaireObject, otpVerificationObject?.pii_user);
            setPaymentQuestionnaireObject(updatedQuestions);
        }

        //Existing Customer
        if(!otpVerificationObject?.pii_user?.customer_id){
            console.info("Already a Customer")
        }else{
            navigate("/address-question");
        }
        navigate("/address-question");

        trackVerificationCompletedEvent()
        // navigate("/communication")
    }

    const otpStyles = {
        width: "250px",
        marginTop: isMobile ? "1rem" : "0rem",
        otpInput: {
            maxWidth: "100%",
            width: "56px",
            height: "64px",
            marginRight: "10px",
            borderRadius: "12px",
            border: "1px solid #35394A",
            background: "#0D0E11",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "24px",
            textUnderlinePosition: "from-font",
            textDecorationSkipInk: "none",
            color: "#D3D3DA",
            boxShadow: "none",
            outline: "none",
            textAlign: "center"
        }
    }

    const verificationStyles = {
        block: {
            background: "#121317",
            padding: isMobile ? "20px" : "60px",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "40px",
        },
        verificationBlock: {
            display: "flex",
            flexDirection: "column",
            gap: "20px",
        },
        topBlock: {
            display: "flex", 
            flexDirection: "column", 
            gap: isMobile ? "24px" : "32px" 
        },
        titleText: {
            fontSize: isMobile ? "16px" : "20px",
            fontWeight: 400,
            lineHeight: '26.52px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            color: '#D3D3DA',
            margin: '0',
        },
        contactNumberText: {
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: '16.8px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            color: '#A1A1AF',
            margin: '0',
        },
        mobilenumber: {
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: '19.2px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            // color: '#D3D3DA',
            marginTop: '0.5rem',
            marginBottom: '0rem',
            color: "#F1F3F6"
        },
        description: {
            fontSize: isMobile ? "14px" : "16px",
            fontWeight: 400,
            lineHeight: isMobile ? "21px" : '23.22px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            color: '#A1A1AF',
            marginBottom: '1.5rem',
            color: "#A1A1AF"
        },
    }

    return (
        <Squircle cornerRadius={30} className='inner-block' style={verificationStyles?.block} >
        {/* <div className='inner-block' style={verificationStyles?.block}> */}

            {!isSendOTP && (
                <section className='verification-block' style={verificationStyles?.verificationBlock}>
                    <div style={verificationStyles?.topBlock}>
                        <p style={verificationStyles?.titleText}>Verify your contact number </p>
                        <div>
                            <p style={verificationStyles?.contactNumberText}>Contact Number </p>

                            {leadInfo?.country_code && leadInfo?.mobile && (
                                <p style={verificationStyles?.mobilenumber}>
                                    +{formatPhoneNumber(leadInfo?.country_code, leadInfo?.mobile)}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className='text-start'>
                        <Button
                            variant="contained"
                            size='small'
                            color='success'
                            sx={commonStyles?.button?.contained}
                            onClick={() => {
                                // navigate("/address-question");
                                handleCheckUser()
                                // handleSendOTP()
                            }}
                        >
                            <span>{"Send OTP"}</span>
                        </Button>
                    </div>
                </section>)}

            {isSendOTP && (
                <section className='verification-block' style={verificationStyles?.verificationBlock}>

                    {otpVerificationObject?.is_verified && (
                        <p style={{
                            ...commonStyles?.typography?.paragraph_1,
                            color: "#5DD57B",
                            marginTop: "0rem",
                            marginBottom: "0rem"
                        }}
                        >
                            <FontAwesomeIcon icon={faCircleCheck} className='me-2' />Verified
                        </p>
                    )}

                    <div style={verificationStyles?.topBlock}>
                        <div>
                            <p style={verificationStyles?.titleText}>Verify your contact number </p>

                            {leadInfo?.country_code && leadInfo?.mobile && (
                                <p style={{...commonStyles?.typography?.paragraph_1, marginBottom: "0", marginTop: "0.5rem"}}>OTP has been sent to +{formatPhoneNumber(leadInfo?.country_code, leadInfo?.mobile)} </p>
                            )}
                        </div>

                        <div style={otpStyles} >
                            <OTPInput
                                className="input"
                                value={otpInput}
                                onChange={(otpValue) => {
                                    setOtpInput(otpValue)
                                    setIsIncorrectOTP(false)

                                    if (otpValue.length === 4) {
                                        document.activeElement?.blur();
                                    }
                                }}
                                numInputs={4}
                                renderSeparator={""}
                                // renderInput={(props) => 
                                //     <input {...props} 
                                //         style={{
                                //             ...otpStyles.otpInput,
                                //             borderColor: isIncorrectOTP ? "#FF564D" : "#35394A"
                                //         }}
                                //     />}
                                renderInput={(props, index) => (
                                    <input
                                        {...props}
                                        style={{
                                            ...otpStyles.otpInput,
                                            borderColor: isIncorrectOTP ? "#FF564D" : "#35394A",
                                        }}
                                        onFocus={(e) => {
                                            e.target.selectionStart = e.target.selectionEnd; // Ensure cursor is at the end
                                        }}
                                    />
                                )}
                                inputType={"tel"}
                            />

                        </div>
                    </div>

                    {!otpVerificationObject?.is_verified &&
                        <div>
                        
                        {isIncorrectOTP &&
                            <p style={{
                                ...commonStyles?.typography?.paragraph_1,
                                color: "#FF564D",
                                marginTop: "0.5rem",
                                marginBottom: "0.5rem"
                            }}>
                                Incorrect OTP
                            </p>}
                            {otpTimeOut ? (
                                <p
                                    style={{
                                        ...commonStyles?.typography?.paragraph_1,
                                        color: "#71F0FF",
                                        cursor: "pointer",
                                        marginBottom: "0rem",
                                        marginTop: "0rem"
                                    }}                                    
                                    onClick={() => {
                                        handleSendOTP();
                                        setOtpInput("");
                                        setIsIncorrectOTP(false);
                                    }}
                                >
                                    Resend OTP
                                </p>
                            ) : (
                                <p style={{
                                    ...commonStyles?.typography?.paragraph_1,
                                    color: "#A1A1AF",
                                    marginBottom: "0rem",
                                    marginTop: "0rem"
                                }}  >Resend OTP in {formattedTime}</p>
                            )}
                        </div>
                    }


                    <div className='text-start'>
                        <Button
                            variant="contained"
                            size='small'
                            color='success'
                            disabled={otpInput?.length !== 4 }
                            sx={otpInput?.length !== 4 ? commonStyles?.button?.disabled : commonStyles?.button?.contained}
                            onClick={() => {
                                if(otpVerificationObject?.is_verified){
                                    handleNavigation()
                                }else{
                                    handleVerifyOTP()
                                }
                            }}
                        >
                            <span>{"Next"}</span>
                        </Button>
                    </div>
                </section>)}

        {/* </div> */}
        </Squircle>
    )
}

export default Verification