import React, { useContext } from 'react';
import './PaymentFlow.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import DHKitImage from '../../assets/img/dh-kit.png';
import { Box, Container, Typography, useMediaQuery } from '@mui/material';
import { commonStyles } from '../common/elements/commonStyle';
import Grid from '@mui/material/Grid2';
import MainContext from '../../context/MainContext';

const CompleteOnboarding = () => {

    const isMobile = useMediaQuery("(max-width:768px)");

    const { isBetaUserDiscount } = useContext(MainContext)
    
    const finalPageStyles = {
        container: {
            minHeight: isMobile ? "calc(100vh - 100px)" : "calc(100vh - 60px)",
            width: "100%",
            display: "flex",
            justifyContent: "start",
            alignItems: isMobile ? "start" : "center",
            padding: "2rem"
        },
        contentSection:{
            display: "flex",
            justifyContent: isMobile ? "start" : "center",
            flexDirection: "column",
            height: "100%"
        },
        typography: {
            headerText: {
                fontSize: isMobile ? "20px" : "32px",
                fontWeight: "400",
                lineHeight: isMobile ? "1.3" : "42.43px",
                textAlign: "left",
                textUnderlinePosition: "from-font",
                textDecorationSkipInk: "none",
                color: "#E1E1E5",
                marginBottom: "1rem",
            },
            successText: {
                fontSize: isMobile ? "16px" : "20px",
                fontWeight: "400",
                lineHeight: "26.52px",
                textAlign: "left",
                textUnderlinePosition: "from-font",
                textDecorationSkipInk: "none",
                color: "#5DD57B",
                marginBottom: "0.5rem",
                '@media (max-width: 768px)': {
                    fontSize: '16px',
                    lineHeight: '34px',
                },
            },
            description:{
                fontSize: isMobile ? "14px" : "16px",
                lineHeight: isMobile ? "20px" : '23.22px',
                fontWeight: 400,
                textAlign: 'left',
                textUnderlinePosition: 'from-font',
                textDecorationSkipInk: 'none',
                marginBottom: '1.5rem',
                color: "#D3D3DA"
            }
        },
        kitImage: {
            position: "relative",
            image: {
                width: "100%",
                ...isMobile && {
                    // position: "absolute",
                    // top: "-2rem",
                    // left: "-2rem",
                    width: "125%"
                }                
            }
        }
    }

    return (
        <Container maxWidth="lg"  style={finalPageStyles?.container}>
            <div className="">
                <Grid container spacing={2}>
                    <Grid size={{ md: 5 }}>
                        <section className="final-page-section" style={finalPageStyles?.contentSection}>
                           
                            <Typography sx={finalPageStyles?.typography?.successText} >
                                <FontAwesomeIcon icon={faCircleCheck} className='me-2' />  {isBetaUserDiscount ? ("All done!") : ("Payment successful")}
                            </Typography>
                            
                            
                            <Typography sx={finalPageStyles?.typography?.headerText} >{!isBetaUserDiscount && ("All done!")} Your kit will be shipped soon.</Typography>

                            {isBetaUserDiscount ? (
                                <Typography style={finalPageStyles?.typography?.description} >
                                    The first kit will be shipped to the provided address within two weeks.
                                    We will keep you posted with further updates.
                                </Typography>
                            ) : (
                                <Typography style={finalPageStyles?.typography?.description} >
                                    We will send an invoice to your email shortly.
                                    The first kit will be shipped to the provided address within two weeks.
                                    We will keep you posted with further updates.
                                </Typography>
                            )}
                           
                        </section>
                    </Grid>
                    <Grid size={{ md: 7 }}>
                        <Box sx={finalPageStyles?.kitImage} >
                            <img
                                style={finalPageStyles?.kitImage?.image}
                                src={DHKitImage}
                                alt="deep-holistics-kit"
                            />
                        </Box>
                    </Grid>
                </Grid>
                
               
            </div>
        </Container>
    );
};

export default CompleteOnboarding;
