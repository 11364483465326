export const commonStyles = {
    dhlogo : {
        textAlign: "start",
        marginBottom: "1rem",
        img: {
            width: "165px",
            height: "100%"
        }
    },
    typography: {
        header_1: {
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: '26.52px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            color: '#D3D3DA',
            marginBottom: '2.5rem',
        },
        header_1_1: {
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: '26.52px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            color: '#D3D3DA',
            marginBottom: '1rem',
        },
        paragraph_1: {
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: '23.22px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            // color: '#D3D3DA',
            marginBottom: '1.5rem',
            color: "#A1A1AF"
        },
        paragraph_2: {
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: '16.8px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            color: '#D3D3DA',
            marginBottom: '1rem',
            color: "#A1A1AF"
        },
        mobilenumber: {
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: '19.2px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            // color: '#D3D3DA',
            marginTop: '0.5rem',
            marginBottom: '0.75rem',
            color: "#F1F3F6"
        },
        link: {
            textDecorationLine: "underline",
            textDecorationStyle: "solid",
            textUnderlinePosition: "from-font",
            textDecorationSkipInk: "auto",
            color: "#70ACFF"
        },
        
    },
    button: {
        disabled: {
            width: 'fit-content',
            height: '48px',
            padding: '12px 28px',
            borderRadius: '12px',
            background: "#545463",
            color: "#1A1A1E",
            marginTop: "1.25rem",
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "23.87px",
            textAlign: "left",
            textUnderlinePosition: "from-font",
            textDecorationSkipInk: "none",
            textTransform: "capitalize",
            'span': {
                // marginTop: "3px",
                lineHeight: "100%  !important",
            },
            '& span': {
                // marginTop: "3px !important",
                fontWeight: "600 !important",
                fontSize: "18px !important",
                lineHeight: "100%  !important",
            },
            "&:disabled": {
                background: "#545463",
                color: "#1A1A1E",
            }, 
            '@media (max-width: 768px)': {
                fontSize: '16px',
                lineHeight: '21px',
                height: '48px',
                borderRadius: '10px',
            },           
        },
        contained: {
            width: 'fit-content',
            height: '48px',
            padding: '12px 28px',
            borderRadius: '12px',
            background: "#E1E1E5",
            color: "#0E0E11",
            marginTop: "1.25rem",
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "23.87px",
            textAlign: "left",
            textUnderlinePosition: "from-font",
            textDecorationSkipInk: "none",
            textTransform: "capitalize",
            'span': {
                // marginTop: "3px",
                lineHeight: "100%  !important",
            },
            '& span': {
                // marginTop: "3px !important",
                fontWeight: "600 !important",
                fontSize: "18px !important",
                lineHeight: "100%  !important",
            },
            "&:disabled": {
                background: "#545463",
                color: "#1A1A1E",
            },
            '@media (max-width: 768px)': {
                fontSize: '16px',
                lineHeight: '21px',
                height: '48px',
                borderRadius: '10px',
            },
        },
        outlined: {
            width: 'fit-content',
            height: '48px',
            padding: '12px 28px',
            borderRadius: '12px',
            border: "1px solid #D3D3DA",
            background: "transparent",
            color: "#D3D3DA",
            marginTop: "1.25rem",
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "23.87px",
            textAlign: "left",
            textUnderlinePosition: "from-font",
            textDecorationSkipInk: "none",
            textTransform: "capitalize",
            'span': {
                // marginTop: "3px",
                lineHeight: "100%  !important",
            },
            '& span': {
                // marginTop: "3px !important",
                fontWeight: "600 !important",
                fontSize: "18px !important",
                lineHeight: "100%  !important",
            },
            '@media (max-width: 768px)': {
                fontSize: '16px',
                lineHeight: '21px',
                height: '48px',
                borderRadius: '10px',
            },
        }
    },
}