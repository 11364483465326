import React, { useContext, useEffect, useState } from 'react';
import MainContext from '../../context/MainContext';
import { sendScheduledEventCallbackURL } from '../../api-functions/ApiURLs';
import { calendlyGetScheduledEvent, handlePostApiCall } from '../../api-functions/CommonApiCall';
import { formatDateRange } from '../common/CommonFunctions';
import "./Eligibility.scss";
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VideoIcon from '../../assets/svg/video.svg'
import CalendarIcon from '../../assets/svg/calendar.svg'
import UserIcon from '../../assets/svg/user.svg'
import WebIcon from '../../assets/svg/web.svg'
import { Box, Container, Typography, useMediaQuery } from '@mui/material';
import { trackEndEligibilitySessionEvent, trackSuccessScheduleEvent } from '../common/Amplitute';

const BookAppointment = () => {

    const isMobile = useMediaQuery("(max-width:768px)");

    const { setIsPreLoading, leadId, leadInfo } = useContext(MainContext);
    const { REACT_APP_CALENDLY_URL, REACT_APP_CALENDLY_API_URL, REACT_APP_CALENDLY_API_TOKEN } = process.env;

    const [scheduledInfo, setScheduledInfo] = useState([])
    const [scheduledDone, setScheduledDone] = useState(false)

    // Load Calendly script on component mount
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        const handleEventScheduled = (event) => {

            if (event.data.event === 'calendly.event_type_viewed') {
                // setIsLoading(false)
            }

            if (event.data.event === 'calendly.event_scheduled') {
                setScheduledDone(true)
                const responseURI = event?.data?.payload?.event?.uri;
                let responseEventID = responseURI?.split('/').pop();
                getScheduledEventData(responseEventID)
                if (responseEventID) {
                    trackSuccessScheduleEvent()
                }

            }
        };

        window.addEventListener('message', handleEventScheduled);

        return () => {
            window.removeEventListener('message', handleEventScheduled);
        };
    }, []);

    const getScheduledEventData = async (event_uuid) => {
        try {
            const response = await calendlyGetScheduledEvent(`${REACT_APP_CALENDLY_API_URL}/${event_uuid}`, REACT_APP_CALENDLY_API_TOKEN);
            setScheduledInfo(response?.data?.resource)
            await sendScheduleEvent(event_uuid);
        } catch (error) {
            console.error("Error fetching data:", error.response ? error.response.data : error.message);
        } finally {
            //   setIsPreLoading(false);
        }
    }

    const sendScheduleEvent = async (event_uuid) => {

        const payload = {
            lead_id: leadId,
            email_type: "meeting_invite_sales",
            event_uuid: event_uuid
        };

        try {
            setIsPreLoading(true)
            const response = await handlePostApiCall(sendScheduledEventCallbackURL, payload);
        } catch (error) {
            console.error("Error fetching data:", error.response ? error.response.data : error.message);
        } finally {
            setIsPreLoading(false);
            trackEndEligibilitySessionEvent()
        }
    };

    const appointmentStyles = {
        block:{
            margin: "4rem 0rem",
            '@media (max-width: 768px)': {
                margin: "3rem 1rem",
            },
            inner: {
                background: "#121317",
                padding: "60px",
                borderRadius: "20px",
                display: "flex",
                flexDirection: "column",

                '@media (max-width: 768px)': {
                    maxWidth: "100%",
                    borderRadius: "16px",
                    padding: "20px",
                },
            }
        },
        typography: {
            headerText: {
                fontSize: "32px",
                fontWeight: "400",
                lineHeight: "42.43px",
                textAlign: "left",
                textUnderlinePosition: "from-font",
                textDecorationSkipInk: "none",
                color: "#E1E1E5",
                marginBottom: "1.5rem",
                '@media (max-width: 768px)': {
                    fontSize: '20px',
                    lineHeight: '34px',
                    marginBottom: "1rem",
                },
            },
            titleText: {
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "26.52px",
                textAlign: "left",
                textUnderlinePosition: "from-font",
                textDecorationSkipInk: "none",
                color: "#F1F3F6",
                marginBottom: "0.75rem",
                '@media (max-width: 768px)': {
                    fontSize: '16px',
                    lineHeight: '34px',
                    marginBottom: "0.5rem",
                },
            },
            subText: {
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "21.2px",
                textAlign: "left",
                textUnderlinePosition: "from-font",
                textDecorationSkipInk: "none",
                color: "#A1A1AF",
                marginBottom: "1.5rem",
                '@media (max-width: 768px)': {
                    fontSize: '14px',
                    lineHeight: '19px',
                    marginBottom: "1rem",
                },
            },
            content: {
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "21.22px",
                textAlign: "left",
                textUnderlinePosition: "from-font",
                textDecorationSkipInk: "none",
                color: "#D3D3DA",
                marginBottom: "1.5rem",
                display: "flex",
                alignItem: "center",
                '@media (max-width: 768px)': {
                    fontSize: '14px',
                    marginBottom: "1rem",
                },
            },
        }
    }

    return (
        <Container maxWidth="lg" sx={{minHeight: isMobile ? "calc(100vh - 100px)" : "calc(100vh - 60px)"}}>
            {!scheduledDone ? (
                <div className="main-component-block pt-3">
                    <div
                        className="calendly-inline-widget"
                        data-url={`${REACT_APP_CALENDLY_URL}?hide_event_type_details=0&hide_gdpr_banner=1&name=${leadInfo?.name || ""}&email=${leadInfo?.email || ""}`}
                        style={{
                            minWidth: '320px',
                            width: "100%",
                            height: 'calc(100vh - 1rem)'
                        }}
                    ></div>
                </div>
            ) : (
                <Box className={`appointement-block ${scheduledDone ? "scheduled" : ""}`} sx={appointmentStyles.block}>
                    <div className='appointement'>
                        <Typography sx={appointmentStyles?.typography?.headerText} >
                            <FontAwesomeIcon icon={faCircleCheck} className='me-2' style={{color: "#5DD57B"}} /> Your meeting is scheduled
                        </Typography>

                        <Box sx={appointmentStyles.block.inner}>
                            <Typography sx={appointmentStyles?.typography?.titleText} >
                                {scheduledInfo?.name || "Deep Holistics Meeting"}
                            </Typography>
                            <Typography sx={appointmentStyles?.typography?.subText} >
                                {"A calendar invitation would be sent to your email address. "}
                            </Typography>
                            <Typography sx={appointmentStyles?.typography?.content} >
                                <img src={UserIcon} style={{marginRight: "0.5rem", width: "14px"}} alt=""/>
                                Deep Holistics
                            </Typography>
                            <Typography sx={appointmentStyles?.typography?.content} >
                                <img src={CalendarIcon} style={{marginRight: "0.5rem", width: "14px"}} alt=""/>
                                <span>{scheduledInfo?.start_time ? formatDateRange(scheduledInfo?.start_time, scheduledInfo?.end_time) : ""}</span>
                            </Typography>
                            <Typography sx={appointmentStyles?.typography?.content} >
                                <img src={WebIcon} style={{marginRight: "0.5rem", width: "14px"}} alt=""/>
                                <span>Indian Standard Time</span>
                            </Typography>
                            <Typography sx={appointmentStyles?.typography?.content} >
                                <img src={VideoIcon} style={{marginRight: "0.5rem", width: "14px"}} alt=""/>
                                <span>Web conferencing details to follow</span>
                            </Typography>
                            
                            {/* {scheduledInfo?.location?.join_url} */}
                        </Box>
                    </div>
                </Box>
            )}
        </Container>
    );
};

export default BookAppointment;
