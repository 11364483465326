import React, { useContext, useEffect } from 'react';
import './Eligibility.scss';
import { Button, Container, Typography, useMediaQuery } from '@mui/material';
import MainContext from '../../context/MainContext';
import { useNavigate } from 'react-router';
import { trackEnterScheduleEvent, trackInEligibilityEvent } from '../common/Amplitute';
import { commonStyles } from '../common/elements/commonStyle';

const Eligibility = () => {

    const isMobile = useMediaQuery("(max-width:768px)");

    const { leadEligibility } = useContext(MainContext);

    const {REACT_APP_CALENDLY_ENABLE} = process.env;

    const navigate = useNavigate()

    useEffect(()=>{
        if(!leadEligibility){
            trackInEligibilityEvent()
        }
    },[leadEligibility])

    const eligibilityStyles = {
        typography: {
            headerText: {
                fontSize: "32px",
                fontWeight: "400",
                lineHeight: "42.43px",
                textAlign: "left",
                textUnderlinePosition: "from-font",
                textDecorationSkipInk: "none",
                color: "#E1E1E5",
                marginBottom: "1.5rem",
                '@media (max-width: 768px)': {
                    fontSize: '24px',
                    lineHeight: '34px',
                },
            },
            content: {
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "21.22px",
                textAlign: "left",
                textUnderlinePosition: "from-font",
                textDecorationSkipInk: "none",
                color: "#E1E1E5",
                marginBottom: "1.5rem",
                '@media (max-width: 768px)': {
                    fontSize: '14px',
                },
            },
        }
    }
 
    return (
        <Container maxWidth="lg" sx={{minHeight: isMobile ? "calc(100vh - 100px)" : "calc(100vh - 60px)"}}>
            <div className="main-component-block">
                <section className="eligibility-section position-center">

                    <Typography sx={eligibilityStyles?.typography?.headerText} >Thank you for your interest in the Human Token.</Typography>
                 
                    {leadEligibility ? <>

                        {REACT_APP_CALENDLY_ENABLE !== "true" ?
                            (<>
                                <Typography sx={eligibilityStyles?.typography?.content} >
                                    We're thrilled to support you on your health journey with Deep Holistics!
                                </Typography>
                                <Typography sx={eligibilityStyles?.typography?.content} >
                                    Our team will reach out to you over an email to discuss the next steps.
                                </Typography>
                            </>)
                            : (<>
                                <Typography sx={eligibilityStyles?.typography?.content} >
                                    We're excited to support you on your health journey with Deep Holistics!
                                </Typography>
                                <Typography sx={eligibilityStyles?.typography?.content} >
                                    Book a session with our team to explore the program in detail, get answers to your questions, and take the next step by signing up for the program.
                                    Let's start your journey to holistic health together!
                                </Typography>

                                <div className="text-start">
                                    <Button
                                        variant="contained"
                                        size="small"
                                        color="success"
                                        sx={commonStyles?.button?.contained}
                                        aria-label={"Schedule an appointment"}
                                        onClick={() => {
                                            trackEnterScheduleEvent()
                                            navigate("/schedule-appointment")
                                        }}
                                    >
                                        <span>{"Schedule appointment"}</span>
                                    </Button>
                                </div>
                            </>)}
                    </> :
                        <>
                            <Typography sx={eligibilityStyles?.typography?.content} >
                                At this time, our program is not equipped to accommodate certain medical conditions. {/* , including [specific condition]. */}
                            </Typography>
                            <Typography sx={eligibilityStyles?.typography?.content} >
                                We truly appreciate your understanding and would be happy to keep you updated on future offerings that might be a better fit.
                            </Typography>
                            
                            <div className="text-start">
                                    <Button
                                        variant="contained"
                                        size="small"
                                        color="success"
                                        sx={commonStyles?.button?.outlined}
                                        aria-label={"Contact Us"}
                                        onClick={() => {
                                            window.open("http://deepholistics.com/support", "_blank");
                                        }}
                                    >
                                        <span>{"Contact Us"}</span>
                                    </Button>
                                </div>
                        
                        </>}

                </section>
            </div>
        </Container>
    );
};

export default Eligibility;
