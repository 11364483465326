
import { parsePhoneNumberFromString } from "libphonenumber-js";
import countryJSON from '../json/countries_list.json';

export const formatDateRange = (start_time, end_time) => {
    
    const options = {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    };

    const startDate = new Date(start_time);
    const endDate = new Date(end_time);

    const startTimeFormatted = startDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
    const endTimeFormatted = endDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });

    const dateFormatted = startDate.toLocaleDateString('en-US', options);

    return `${startTimeFormatted} - ${endTimeFormatted}, ${dateFormatted}`;
}

export const formatPhoneNumber = (countryCode, phoneNumber) => {
    const countrySelect = countryJSON?.find(country => country?.phone_code === countryCode);
    const parsedNumber = parsePhoneNumberFromString(phoneNumber, countrySelect?.iso2);
    const formattedPhoneNumber = (parsedNumber?.formatNational()).replace(/^0+/, '')

    if (parsedNumber && parsedNumber.isValid()) {
        return `${parsedNumber.countryCallingCode} ${formattedPhoneNumber}`;
    } else {
        return "Invalid phone number";
    }
}