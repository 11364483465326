import React from 'react'
import './PaymentFlow.scss';
import { Autocomplete, Box, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCircleCheck, faDisplay } from '@fortawesome/free-solid-svg-icons';
import RadioButton from '../../assets/svg/RadioButton.svg';
import RadioButtonEnabled from '../../assets/svg/RadioButtonEnabled.svg';
import AutoCompleteSelect from '../common/elements/SelectField';
import InputTextField from '../common/elements/InputTextField';
import InputLabelField from '../common/elements/InputLabelField';
import { commonStyles } from '../common/elements/commonStyle';

const AddressForm = ({
    title,
    addressData,
    onChange,
    countries,
    states,
    cities,
    selectedCountry,
    selectedState,
    selectedCity,
    isCountryChanged,
    isStateChanged,
    isCityChanged,
    onCountryChange,
    onStateChange,
    onCityChange,
}) => {

    const isMobile = useMediaQuery("(max-width: 768px)");

    const addressStyle = {
        title: {
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: '26.52px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            color: '#D3D3DA',
            marginBottom: '1.5rem',
            marginTop: '0px',
        },
        addresBlock:{
            display: "flex",
            flexDirection: "column",
            gap: isMobile ? "24px" :"32px"
        },
        addressBoxStyle: {
            // marginBottom: "2rem", 
            textAlign: "left"
        }
    }

    return (
        <section className="address-details-block">
            <p style={addressStyle?.title}>{title}</p>
            <div  style={addressStyle?.addresBlock}>
                <div style={addressStyle?.addressBoxStyle}>
                    <InputLabelField
                        htmlFor={"address_line_1"}
                        label={"Address Line 1"}
                        isRequired={true}
                    />
                    <InputTextField
                        value={addressData?.address_line1}
                        onChange={onChange}
                        name="address_line1"
                        // suggestionName="address_line1"
                    />
                </div>
                <div style={addressStyle?.addressBoxStyle}>
                    <InputLabelField
                        htmlFor={"address_line_2"}
                        label={"Address Line 2"}
                        isRequired={false}
                    />
                    <InputTextField
                        value={addressData?.address_line2}
                        onChange={onChange}
                        name="address_line2"
                    />
                </div>
                <div style={addressStyle?.addressBoxStyle}>
                    <InputLabelField
                        htmlFor={"zipcode"}
                        label={"Pincode"}
                        isRequired={true}
                    />
                    <InputTextField 
                        value={addressData?.zipcode}
                        onChange={onChange}
                        name="zipcode"
                    />
                </div>
                <div style={addressStyle?.addressBoxStyle}>
                    <InputLabelField
                        htmlFor={"country"}
                        label={"Country"}
                        isRequired={false}
                    />
                    <AutoCompleteSelect 
                        optionsData={countries || []}
                        selectedOption={selectedCountry}
                        onChange={(newValue) => onCountryChange(newValue)}
                        changeState={isCountryChanged}
                        type="country"
                    />
                </div>

                <div style={addressStyle?.addressBoxStyle}>
                    <InputLabelField
                        htmlFor={"state"}
                        label={"State"}
                        isRequired={false}
                    />
                    <AutoCompleteSelect 
                        optionsData={states || []}
                        selectedOption={selectedState}
                        onChange={(newValue) => onStateChange(newValue)}
                        changeState={isStateChanged}
                        type="state"
                    />
                </div>

                <div style={addressStyle?.addressBoxStyle}>
                    <InputLabelField
                        htmlFor={"city"}
                        label={"City"}
                        isRequired={false}
                    />
                    <AutoCompleteSelect 
                        optionsData={cities || []}
                        selectedOption={selectedCity}
                        onChange={(newValue) => onCityChange(newValue)}
                        changeState={isCityChanged}
                        type="city"
                    />
                </div>
            </div>
        </section>
    )
}

export default AddressForm