import amplitude from 'amplitude-js';
import { autocapturePlugin } from '@amplitude/plugin-autocapture-browser';

const { REACT_APP_AMPLITUTE_KEY, REACT_APP_AMPLITUTE_ENABLE } = process.env;

const AMPLITUDE_ENABLED = REACT_APP_AMPLITUTE_ENABLE === 'true';
const amplitudeInstance = amplitude.getInstance();
amplitudeInstance.init(REACT_APP_AMPLITUTE_KEY);

/* Middleware to log events only if Amplitude is enabled */
const trackAmplitudeEvent = (eventName, eventProperties = {}) => {
    if (AMPLITUDE_ENABLED) {
        amplitudeInstance.logEvent(eventName, eventProperties);
    }
};

/* Set Common Properties */
export const setAmplitudeUserID = (userID) => {
    if (AMPLITUDE_ENABLED) {
        amplitudeInstance.setUserId(userID);
    }
};

export const setUserProperties = (propertiesObject) => {
    if (AMPLITUDE_ENABLED) {
        amplitudeInstance.setUserProperties(propertiesObject);
    }
};

export const startAmplitudeSession = () => {
    if (AMPLITUDE_ENABLED) {
        amplitudeInstance.init(process.env.REACT_APP_AMPLITUTE_KEY, {
            autocapture: {
                elementInteractions: true
            }
        });
        trackAmplitudeEvent('Start Session - Onboarding');
    }
};

/* Onboarding Events */
export const trackWaitlistFormEnterEvent = () => trackAmplitudeEvent('Clicked join waitlist', { project_name: 'Onboarding Deep Holistics' });
export const trackWaitlistFormSubmissionEvent = () => trackAmplitudeEvent('Submitted the waitlist form', { project_name: 'Onboarding Deep Holistics' });

/* Check Eligibility - Flow */
export const trackEnterCheckEligibilityEvent = () => trackAmplitudeEvent('Starts Check Eligibility', { project_name: 'Onboarding Deep Holistics' });
export const trackCompleteCheckEligibilityEvent = () => trackAmplitudeEvent('Completes Check Eligibility', { project_name: 'Onboarding Deep Holistics' });
export const trackInEligibilityEvent = () => trackAmplitudeEvent('InEligibility', { project_name: 'Onboarding Deep Holistics' });
export const trackEnterScheduleEvent = () => trackAmplitudeEvent('Enter Schedule Meeting', { project_name: 'Onboarding Deep Holistics' });
export const trackSuccessScheduleEvent = () => trackAmplitudeEvent('Meeting Scheduled', { project_name: 'Onboarding Deep Holistics' });
export const trackEndEligibilitySessionEvent = () => trackAmplitudeEvent('End Session - Check Eligibility Onboarding', { project_name: 'Onboarding Deep Holistics' });

/* Verification - Payment Flow */
export const trackExistingCustomerEvent = () => trackAmplitudeEvent('Already Existing Customer', { project_name: 'Onboarding Deep Holistics' });
export const trackWhatsappConsentEnableEvent = () => trackAmplitudeEvent('Whatsapp Consent Enabled', { project_name: 'Onboarding Deep Holistics' });
export const trackWhatsappConsentDisableEvent = () => trackAmplitudeEvent('Whatsapp Consent Disabled', { project_name: 'Onboarding Deep Holistics' });
export const trackSendOTPEvent = () => trackAmplitudeEvent('Send OTP', { project_name: 'Onboarding Deep Holistics' });
export const trackVerifyOTPEvent = () => trackAmplitudeEvent('Verify OTP', { project_name: 'Onboarding Deep Holistics' });
export const trackVerificationCompletedEvent = () => trackAmplitudeEvent('Verification Completed', { project_name: 'Onboarding Deep Holistics' });
export const trackAgreeTermsEvent = () => trackAmplitudeEvent('Agree the Terms & Conditions', { project_name: 'Onboarding Deep Holistics' });
export const trackDisagreeTermsEvent = () => trackAmplitudeEvent('Disagree the Terms & Conditions', { project_name: 'Onboarding Deep Holistics' });
export const trackAddressQuestionnaireCompletedEvent = () => trackAmplitudeEvent('Address Questionnaire Completed', { project_name: 'Onboarding Deep Holistics' });
export const trackStartPaymentEvent = () => trackAmplitudeEvent('Start Payment', { project_name: 'Onboarding Deep Holistics' });
export const trackCompletedPaymentEvent = () => trackAmplitudeEvent('Payment completed', { project_name: 'Onboarding Deep Holistics' });
export const trackEndPaymentSessionEvent = () => trackAmplitudeEvent('End Session - Payment Onboarding', { project_name: 'Onboarding Deep Holistics' });
