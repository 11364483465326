import axios from "axios";
import Cookies from "universal-cookie";

const { REACT_APP_BASE_URL, REACT_APP_CLIENT_ID } = process.env;
const cookies = new Cookies();

const createAxiosInstance = (baseURL) => {
    const instance = axios.create({
        baseURL,
        headers: {
            client_id: REACT_APP_CLIENT_ID,
            // access_token: cookies.get("access_token"),
            user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
    });

    instance.interceptors.request.use(
        (req) => {
            // const token = cookies.get("access_token");
            // if (token) {
            //     req.headers.access_token = `${token}`;
            // }
            return req;
        },
        (error) => Promise.reject(error)
    );

    instance.interceptors.response.use(
        (response) => response,
        (error) => Promise.reject(error)
    );

    return instance;
};

const commonApiCall = createAxiosInstance(REACT_APP_BASE_URL);

//Get Api
export const handleGetApiCall = (apiUrl, payload, requestID = null) =>
    new Promise((resolve, reject) => {
        const url = requestID ? `${apiUrl}/${requestID}` : apiUrl;
        commonApiCall
            .get(url, { params: payload })
            .then((response) => {
                resolve({
                    status: "success",
                    data: response.data?.data,
                    message: response.data.message || "",
                });
            })
            .catch((error) => {                
                reject(error?.response?.data);
            });
    });

//Post Api
export const handlePostApiCall = (apiUrl, payload) =>
    new Promise((resolve, reject) => {
        const url = apiUrl;

        const headers = payload instanceof FormData
            ? { 'Content-Type': 'multipart/form-data' }
            : { 'Content-Type': 'application/json' };

        commonApiCall
            .post(url, payload, { headers })
            .then((response) => {
                resolve({
                    status: "success",
                    data: response.data?.data,
                    message: response.data.message || "",
                });
            })
            .catch((error) => {
                reject(error?.response?.data);
            });
    });

//Put Api
export const handlePutApiCall = (apiUrl, payload, requestID = null) =>
    new Promise((resolve, reject) => {
        const url = requestID ? `${apiUrl}/${requestID}` : apiUrl;
        commonApiCall
            .put(url, payload)
            .then((response) => {
                resolve({
                    status: "success",
                    data: response.data,
                    message: response.data.message || "",
                });
            })
            .catch((error) => {
                reject(error?.response?.data);
            });
    });

//Patch Api
export const handlePatchApiCall = (apiUrl, payload, requestID = null) =>
    new Promise((resolve, reject) => {
        const url = requestID ? `${apiUrl}/${requestID}` : apiUrl;
        commonApiCall
            .patch(url, payload)
            .then((response) => {
                resolve({
                    status: "success",
                    data: response.data,
                    message: response.data.message || "",
                });
            })
            .catch((error) => {
                reject(error?.response?.data);
            });
    });

//Delete Api
export const handleDeleteApiCall = (apiUrl, payload = {}, requestID = null) =>
    new Promise((resolve, reject) => {
        const url = requestID ? `${apiUrl}/${requestID}` : apiUrl;
        commonApiCall
            .delete(url, {data: payload})
            .then((response) => {
                resolve({
                    status: "success",
                    data: response.data,
                    message: response.data.message || "",
                });
            })
            .catch((error) => {
                reject(error?.response?.data);
            });
    });

export const calendlyGetScheduledEvent = (apiUrl, token) =>
    new Promise((resolve, reject) => {
        axios
            .get(apiUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                resolve({ status: 'success', data: response.data });
            })
            .catch((error) => {
                reject(
                    error.response?.data || { status: 'error', message: 'Request failed' }
                );
            });
    });
      

export { commonApiCall };