import React from 'react'
import Grid from '@mui/material/Grid2';
import DhLogo from '../../assets/img/logo-white-landscape.svg';
import './PaymentFlow.scss';
import { Container, useMediaQuery } from '@mui/material';
import { commonStyles } from '../common/elements/commonStyle';

const Layout = ({children}) => {
    const isMobile = useMediaQuery("(max-width: 768px)");

    return (
        <Container sx={{
            minHeight: isMobile ? "calc(100vh - 100px)" : "calc(100vh - 60px)"
        }}>
            <section className='payment-flow-section main-component-outer-block'>
                <Grid container spacing={2}>
                    <Grid size={{ md: 7, sm: 12, xs: 12 }}>
                        <div style={commonStyles?.dhlogo}>
                            <img src={DhLogo} alt="deep-holistics-logo" style={commonStyles?.dhlogo.img}/>
                        </div>
                        <section className="main-component">{children}</section>

                    </Grid>
                </Grid>
            </section>
        </Container>
    )
}

export default Layout