import React, { useContext, useState } from 'react'
import './PaymentFlow.scss';
import { Button } from '@mui/material';
import { Checkbox } from 'primereact/checkbox';
import { useNavigate } from 'react-router';

const Communication = () => {

    const navigate = useNavigate();

    return (
        <div className='inner-block'>

            <section className='communication-block'>
                <p className='heading-text'>Mode of communication </p>

                <div className="d-flex flex-column gap-3 checkbox-options">

                    <div className={`checkbox-option`}>  {/*  ${currentQuestion?.data?.value?.includes(option.value) ? "selected" : "not-selected"} */}
                        <label
                            htmlFor={`phone-call`}
                            className={`checkbox-label`}
                        >
                            {"Phone Call"}
                        </label>
                        <Checkbox
                            inputId={`phone-call`}
                            value={"Phone Call"}
                            onChange={(e) => {
                            }}
                        // checked={currentQuestion?.data?.value?.includes(option.value)}
                        />
                    </div>
                    <div className={`checkbox-option`}>
                        <label
                            htmlFor={`e-mail`}
                            className={`checkbox-label`}
                        >
                            {"Email"}
                        </label>
                        <Checkbox
                            inputId={`e-mail`}
                            value={"Email"}
                            onChange={(e) => {
                            }}
                        />
                    </div>
                    <div className={`checkbox-option`}>
                        <label
                            htmlFor={`whatsapp`}
                            className={`checkbox-label`}
                        >
                            {"Whatsapp"}
                        </label>
                        <Checkbox
                            inputId={`whatsapp`}
                            value={"Whatsapp"}
                            onChange={(e) => {
                            }}
                        />
                    </div>
                </div>

                <div className='text-start'>
                    <Button
                        variant="contained"
                        size='small'
                        color='success'
                        className="send-otp-button"
                        // disabled
                        onClick={() => {
                            navigate("/address-question")
                        }}
                    >
                        <span>{"Next"}</span>
                    </Button>
                </div>
            </section>

        </div>
    )
}

export default Communication