import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate
  // Outlet,
} from "react-router-dom";
import WaitlistForm from './components/waitlist-from/WaitlistForm';
import ScreeningQuestionnaire from './components/screening-questionnaire/ScreeningQuestionnaire';
import Eligibility from './components/screening-questionnaire/Eligibility';
import Disclaimer from './components/payment-flow/Disclaimer';
import Payment from './components/payment-flow/Payment';
import Layout from './components/payment-flow/Layout';
import Verification from './components/payment-flow/Verification';
import Communication from './components/payment-flow/Communication';
import AddressQuestions from './components/payment-flow/AddressQuestions';
import { useContext, useEffect } from 'react';
import MainContext from './context/MainContext';
import BookAppointment from './components/screening-questionnaire/BookAppointment';
import CompleteOnboarding from './components/payment-flow/CompleteOnboarding';
import Footer from './components/common/Footer';
import PreLoader from './components/common/PreLoader';
import { ToastContainer } from 'react-toastify';
import ExistingCustomer from './components/payment-flow/ExistingCustomer';
import Cookies from 'universal-cookie';

const CheckPermission = ({ children }) => {
  const cookies = new Cookies();
  const { leadId } = useContext(MainContext);
  const queryParams = new URLSearchParams(window.location.search);
  const leadTokenParam = queryParams.get('lead_id');
  // const cookiesLeadID = cookies.get("lead_id");

  return (leadId || leadTokenParam) ? children : <Navigate to="/waitlist" replace />;
};

function App() {

  const { setLeadId, isPreLoading, leadIsAlreadyPaid ,setIsPreLoading } = useContext(MainContext);

  useEffect(() => {
    if (isPreLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isPreLoading]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const leadTokenParam = queryParams.get('lead_id');

    if (leadTokenParam) {
      setLeadId(leadTokenParam);
    }
  }, [])

  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <Routes>
          <Route
            path="/waitlist"
            element={<WaitlistForm />}
          />

          <Route
            path="/screening-result"
            element={
              <CheckPermission>
                <Eligibility />
              </CheckPermission>
            }
          />

          <Route
            path="/schedule-appointment"
            element={
              <CheckPermission>
                <BookAppointment />
              </CheckPermission>
            }
          />

          <Route
            path="/"
            element={
              <CheckPermission>
                <Layout>
                  <Outlet />
                </Layout>
              </CheckPermission>
            }
          >
            <Route index element={<Disclaimer />} />
            <Route path="/questionnaire-home" element={<ScreeningQuestionnaire />} /> {/* screening-questionnaire */}
            <Route path="/otp-verification" element={<Verification />} />
            <Route path="/communication" element={<Communication />} />
            <Route path="/address-question" element={<AddressQuestions />} />
            <Route path="/payment" element={<Payment />} />
          </Route>
          <Route
            path="/complete"
            element={
              <CheckPermission>
                <CompleteOnboarding />
              </CheckPermission>
            }
          />
          <Route
            path="/existing-customer"
            element={
              <CheckPermission>
                <ExistingCustomer />
              </CheckPermission>
            }
          />

          {/* <Route path="*" element={<NotFoundPage />} /> */}
        </Routes>
      </Router>

      <Footer />

      {isPreLoading && (<PreLoader />)}
    </div>
  );
}

export default App;
